import { Col, Divider, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { setShowLoader } from "../../reducers/loaderSlice";
import Loader from "../Common/Loader";

const Stack = ({ showModal, sparklingData, stackData }) => {
  const dispatch = useDispatch();
  const showLoader = useSelector((state) => state.loader.show);

  const getSensorName = (stackId) => {
    return `Sensor ${stackId}`;
  };

  const getVehicles = (id) => {
    return stackData?.data?.sensors?.find((obj) => obj.sensorId === id);
  };

  const onClickRangeHandler = (filter, sort, id) => {
    showModal(filter, sort, id);
    dispatch(setShowLoader(true));
  };

  return (
    <Row gutter={[16, 16]}>
      {showLoader && <Loader noText={true} />}
      <Col span={24}>
        {stackData?.sensor?.map((item, index) => {
          return (
            <div key={index} className="sensor-card mt1">
              <Row gutter={[0, 16]}>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} className="title pd1">
                  {getSensorName(item?.stackId)}
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="pd05">
                  <Sparklines
                    data={sparklingData?.sensor[item?.stackId]}
                    margin={10}
                  >
                    <SparklinesLine color="#00bfff" />
                  </Sparklines>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row
                    gutter={[8, 16]}
                    justify="space-between"
                    className="pd05"
                  >
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                      <div className="text-center">
                        <div className="mt025 grey f12 ml025">Min</div>
                        <div className="head24">{item?.minTemperature}</div>
                      </div>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                      <Divider type="vertical" style={{ height: "100%" }} />
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                      <div className="text-center">
                        <div className="mt025 grey f12 ml025">Max</div>
                        <div className="head24">{item?.maxTemperature}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    gutter={[16, 16]}
                    justify="space-between"
                    className="pd05"
                  >
                    <Col span={12}>
                      <div className="vehicle-card text-center">
                        <span className="f12 grey mr05">Below Range</span>
                        <span className="head18 mr05">
                          {getVehicles(item?.stackId)?.belowRange}%
                        </span>
                        <span
                          className="link"
                          onClick={() =>
                            onClickRangeHandler("SENSOR", "desc", item?.stackId)
                          }
                        >
                          Vehicles
                        </span>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="vehicle-card text-center">
                        <span className="f12 grey mr05">Above Range</span>
                        <span className="head18 mr05">
                          {getVehicles(item?.stackId)?.aboveRange}%
                        </span>
                        <span
                          className="link"
                          onClick={() =>
                            onClickRangeHandler("SENSOR", "asc", item?.stackId)
                          }
                        >
                          Vehicles
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

export default Stack;
