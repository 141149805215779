import { Col, Row, Divider } from "antd";
import { useSelector } from "react-redux";
import { UNITS } from "../../../../Common/Constants";
import { getGrafanaInterval } from "../../../../Common/Utils";
import SPEED from "../../../../../assets/images/icons/speed.svg";
import AddToCompare from "../addToCompare";
import PanelRow from "../panelRow";
import { grafanaBaseUrl } from "../../../../Common/endpoints";
import CardCollapse from "../../../../Common/CardCollapse";

const SpeedAnalytics = ({ compareValue, updateCompareValue }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  const SpeedTimeSeriesChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=12`;
  const SpeedHistogramChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=13`;
  const SpeedBoxPlotChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=14`;

  const MCSTimeSeriesChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=40`;
  const MCSHistogramChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=41`;
  const MCSBoxPlotChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=42`;

  const MotorTimeSeriesChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=37`;
  const MotorHistogramChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=38`;
  const MotorBoxPlotChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=39`;

  const subContent = (
    <Row gutter={[16, 16]} className="mt05">
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        Average: {tripSummary?.averageSpeed} {UNITS.KM_PER_HR}
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        Statistical Mode: {tripSummary?.statisticalModeOfSpeed}{" "}
        {UNITS.KM_PER_HR}
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        Top Value: {tripSummary?.topSpeed} {UNITS.KM_PER_HR}
      </Col>
    </Row>
  );

  return (
    <CardCollapse
      cardClassName="ride-statistic-analytics-card"
      icon={SPEED}
      title={"Speed Analytics"}
      subContent={subContent}
    >
      <Row className="mt1 pd0">
        <Col span={24}>
          {vehicleDetails?.imeiNumber && (
            <PanelRow
              title={"Speed"}
              timeSeriesURL={SpeedTimeSeriesChart}
              histogramURL={SpeedHistogramChart}
              boxPlotURL={SpeedBoxPlotChart}
            />
          )}
          <Row className="mt1 mb1">
            <Col className="add-compare-col">
              <AddToCompare
                value="speed"
                compareValue={compareValue}
                updateCompareValue={updateCompareValue}
              />
            </Col>
          </Row>
          <Row>
            <Divider
              style={{ background: "#eee", height: "4px", margin: "12px 0" }}
            />
          </Row>
          {vehicleDetails?.imeiNumber && (
            <PanelRow
              title={"MCS Temperature"}
              timeSeriesURL={MCSTimeSeriesChart}
              histogramURL={MCSHistogramChart}
              boxPlotURL={MCSBoxPlotChart}
            />
          )}
          <Row className="mt1 mb1">
            <Col className="add-compare-col">
              <AddToCompare
                value="MCS"
                compareValue={compareValue}
                updateCompareValue={updateCompareValue}
              />
            </Col>
          </Row>
          <Row>
            <Divider
              style={{ background: "#eee", height: "4px", margin: "12px 0" }}
            />
          </Row>
          {vehicleDetails?.imeiNumber && (
            <PanelRow
              title={"Motor Temperature"}
              timeSeriesURL={MotorTimeSeriesChart}
              histogramURL={MotorHistogramChart}
              boxPlotURL={MotorBoxPlotChart}
            />
          )}
          <Row className="mt1 mb1">
            <Col className="add-compare-col">
              <AddToCompare
                value="motor"
                compareValue={compareValue}
                updateCompareValue={updateCompareValue}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CardCollapse>
  );
};

export default SpeedAnalytics;
