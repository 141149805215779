import { Col, Row } from "antd";
import { grafanaBaseUrl } from "../../../../Common/endpoints";
import Iframe from "../../../../Common/Iframe";

const Panels = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <Row
      gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}
    >
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Iframe
          title="Dyno Test History"
          src={`${grafanaBaseUrl}/d-solo/dae3ec3c-2167-4a28-a105-c105579d80c6/eol-dyno-summary?orgId=${userDetails?.currentOrganisation?.id}&panelId=1`}
          width="100%"
          height="300"
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Iframe
          title="Test History"
          src={`${grafanaBaseUrl}/d-solo/dae3ec3c-2167-4a28-a105-c105579d80c6/eol-dyno-summary?orgId=${userDetails?.currentOrganisation?.id}&panelId=2`}
          width="100%"
          height="300"
        />
      </Col>
    </Row>
  );
};

export default Panels;
