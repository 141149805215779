import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { getGrafanaInterval } from "../../../../Common/Utils";
import ENERGY from "../../../../../assets/images/icons/energy.svg";
import AddToCompare from "../addToCompare";
import CardCollapse from "../../../../Common/CardCollapse";
import Iframe from "../../../../Common/Iframe";
import { TEST_RIDE_SUMMARY } from "../../../../Common/Constants";
import FullGraphsModal from "./fullGraphsModal";

const EnergyAnalytics = ({ baseURL, compareValue, updateCompareValue }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  return (
    <CardCollapse
      cardClassName={"ride-statistic-analytics-card"}
      title={"Energy Metrics"}
      icon={ENERGY}
    >
      <Row justify={"end"}>
        <Col className="pd05">
          <FullGraphsModal
            modalTitle="Energy Metrics"
            vehicleDetails={vehicleDetails}
            baseURL={baseURL}
            startTime={tripSummary?.rideForm?.startTime}
            stopTime={tripSummary?.rideForm?.stopTime}
            chartList={["energyMetrics"]}
          />
        </Col>
      </Row>
      <Row className="pd0">
        <Col span={24}>
          {vehicleDetails?.imeiNumber && (
            <Row gutter={[12, 12]} className="pdtb05">
              <Col span={24}>
                <Iframe
                  title={"Time Series - Energy Metrics"}
                  src={`${baseURL}?orgId=${
                    userDetails?.currentOrganisation?.id
                  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
                    tripSummary?.rideForm?.testId
                  }&var-interval=${getGrafanaInterval(
                    tripSummary?.rideForm?.startTime,
                    tripSummary?.rideForm?.stopTime
                  )}&from=${
                    tripSummary?.rideForm?.startTime
                  }&to=${tripSummary?.rideForm?.stopTime}&panelId=${
                    TEST_RIDE_SUMMARY.PANEL_ID["energyMetrics"]["timeSeries"]
                  }`}
                  width="100%"
                  height="240"
                />
              </Col>
            </Row>
          )}
          <Row className="mt1 mb1">
            <Col className="add-compare-col">
              <AddToCompare
                value="energyMetrics"
                compareValue={compareValue}
                updateCompareValue={updateCompareValue}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CardCollapse>
  );
};

export default EnergyAnalytics;
