import { Card, Col, Row } from "antd";
import TOTAL_KM from "../../../../assets/images/icons/totalkm.svg";
import DISTANCE_KM from "../../../../assets/images/icons/distance.svg";
import AVG_SPEED from "../../../../assets/images/icons/avg-speed.svg";
import RIDE_DURATION from "../../../../assets/images/icons/ride-duration.svg";
import STOP_DURATION from "../../../../assets/images/icons/stop-duration.svg";
import { useSelector } from "react-redux";
import { UNITS } from "../../../Common/Constants";
import DISCHARGE from "../../../../assets/images/icons/discharge.svg";
import END_CHARGE from "../../../../assets/images/icons/end-charge.svg";

const Overview = () => {
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  const innerContent = (imgPath, title, count, unit, span) => {
    return (
      <Col xs={24} sm={24} md={span} lg={span} xl={span}>
        <Card
          title={
            <div className="overview-header">
              <img src={imgPath} alt="km" className="icon-size mr05" />
              {title}
            </div>
          }
        >
          <div className="mtb1025 text-center">
            <span className="avg-count">{count}</span>
            <span className="card-sub-text ml015">{unit}</span>
          </div>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <Row gutter={[16, 16]} justify="space-between" className="overview">
        {innerContent(
          TOTAL_KM,
          "Total Distance",
          tripSummary?.totalDistance,
          UNITS.KM,
          6
        )}

        {innerContent(
          DISTANCE_KM,
          "Distance/Charge",
          tripSummary?.distancePerCharge,
          UNITS.KM,
          6
        )}

        {innerContent(
          AVG_SPEED,
          "Avg Speed",
          tripSummary?.averageSpeed,
          UNITS.KM,
          6
        )}

        {innerContent(
          RIDE_DURATION,
          "Ride Time",
          tripSummary?.tripDuration,
          UNITS.HOURS,
          6
        )}
      </Row>
      <Row gutter={[16, 16]} justify="space-between" className="overview mt1">
        {innerContent(
          STOP_DURATION,
          "Stops",
          tripSummary?.stopDuration,
          UNITS.HOURS,
          8
        )}

        {innerContent(
          DISCHARGE,
          "Total Discharge",
          tripSummary?.totalDischarge,
          UNITS.PERCENT,
          8
        )}

        {innerContent(
          END_CHARGE,
          "Ending charge",
          tripSummary?.endSoc,
          UNITS.PERCENT,
          8
        )}
      </Row>
    </>
  );
};

export default Overview;
