import { Card, Col, Row, Select, Tooltip } from "antd";
import {
  BarChartOutlined,
  BoxPlotOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import Iframe from "../../../Common/Iframe";
import { useEffect, useState } from "react";
import { getGrafanaInterval } from "../../../Common/Utils";
import { TEST_RIDE_SUMMARY } from "../../../Common/Constants";

const GrafanaCompare = ({
  vehicleDetails,
  baseURL,
  compareCharts,
  startTime,
  stopTime,
}) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [chartType, setChartType] = useState({
    timeSeries: true,
    histogram: false,
    boxPlot: false,
  });

  const [compareValue, setCompareValue] = useState(compareCharts || []);

  useEffect(() => {
    setCompareValue(compareCharts?.length > 0 ? [...compareCharts] : []);
    setChartType({ timeSeries: true, histogram: false, boxPlot: false });
  }, [compareCharts]);

  const updateCompareValue = (index, value) => {
    const newValue = [...compareValue];
    newValue[index] = value;
    setCompareValue([...newValue]);
  };

  const updateChartType = (name, value) => {
    setChartType((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Row gutter={[16, 16]} className="grafana-compare">
      <Col span={24}>
        <Card
          title={
            <Row gutter={[16, 16]} justify={"space-between"} className="pd05">
              <Col>
                <Row gutter={[16, 16]} align={"middle"}>
                  <Col>
                    <Select
                      placeholder="Select a chart type"
                      value={compareValue?.[0]}
                      style={{ width: "200px" }}
                      onChange={(value) => updateCompareValue(0, value)}
                    >
                      <Select.OptGroup label="Motor">
                        <Select.Option value="speed">Speed</Select.Option>
                        <Select.Option value="MCS">
                          MCS Temperature
                        </Select.Option>
                        <Select.Option value="motor">
                          Motor Temperature
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Battery">
                        <Select.Option value="batteryVoltage">
                          Voltage
                        </Select.Option>
                        <Select.Option value="batteryCurrent">
                          Current
                        </Select.Option>
                        <Select.Option value="batterySOC">SOC</Select.Option>
                        <Select.Option value="batterySOH">SOH</Select.Option>
                        <Select.Option value="batteryMinTemp">
                          Min Temperature
                        </Select.Option>
                        <Select.Option value="batteryMaxTemp">
                          Max Temperature
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Braking">
                        <Select.Option value="brakeDuration">
                          Duration
                        </Select.Option>
                        <Select.Option value="brakeDistance">
                          Distance
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.Option value="stopsDuration">
                        Stops Duration
                      </Select.Option>
                      <Select.Option value="energyMetrics">
                        Energy Metrics
                      </Select.Option>
                      <Select.OptGroup label="Acceleration">
                        <Select.Option value="AccelerationDuration">
                          Duration
                        </Select.Option>
                        <Select.Option value="AccelerationDistance">
                          Distance
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Deceleration">
                        <Select.Option value="DecelerationDuration">
                          Duration
                        </Select.Option>
                        <Select.Option value="DecelerationDistance">
                          Distance
                        </Select.Option>
                      </Select.OptGroup>
                    </Select>
                  </Col>

                  <Col>
                    <Select
                      placeholder="Select a chart type"
                      value={compareValue?.[1]}
                      style={{ width: "200px" }}
                      onChange={(value) => updateCompareValue(1, value)}
                    >
                      <Select.OptGroup label="Motor">
                        <Select.Option value="speed">Speed</Select.Option>
                        <Select.Option value="MCS">
                          MCS Temperature
                        </Select.Option>
                        <Select.Option value="motor">
                          Motor Temperature
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Battery">
                        <Select.Option value="batteryVoltage">
                          Voltage
                        </Select.Option>
                        <Select.Option value="batteryCurrent">
                          Current
                        </Select.Option>
                        <Select.Option value="batterySOC">SOC</Select.Option>
                        <Select.Option value="batterySOH">SOH</Select.Option>
                        <Select.Option value="batteryMinTemp">
                          Min Temperature
                        </Select.Option>
                        <Select.Option value="batteryMaxTemp">
                          Max Temperature
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Braking">
                        <Select.Option value="brakeDuration">
                          Duration
                        </Select.Option>
                        <Select.Option value="brakeDistance">
                          Distance
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.Option value="stopsDuration">
                        Stops Duration
                      </Select.Option>
                      <Select.Option value="energyMetrics">
                        Energy Metrics
                      </Select.Option>
                      <Select.OptGroup label="Acceleration">
                        <Select.Option value="AccelerationDuration">
                          Duration
                        </Select.Option>
                        <Select.Option value="AccelerationDistance">
                          Distance
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Deceleration">
                        <Select.Option value="DecelerationDuration">
                          Duration
                        </Select.Option>
                        <Select.Option value="DecelerationDistance">
                          Distance
                        </Select.Option>
                      </Select.OptGroup>
                    </Select>
                  </Col>

                  <Col>
                    <Select
                      placeholder="Select a chart type"
                      value={compareValue?.[2]}
                      style={{ width: "200px" }}
                      onChange={(value) => updateCompareValue(2, value)}
                    >
                      <Select.OptGroup label="Motor">
                        <Select.Option value="speed">Speed</Select.Option>
                        <Select.Option value="MCS">
                          MCS Temperature
                        </Select.Option>
                        <Select.Option value="motor">
                          Motor Temperature
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Battery">
                        <Select.Option value="batteryVoltage">
                          Voltage
                        </Select.Option>
                        <Select.Option value="batteryCurrent">
                          Current
                        </Select.Option>
                        <Select.Option value="batterySOC">SOC</Select.Option>
                        <Select.Option value="batterySOH">SOH</Select.Option>
                        <Select.Option value="batteryMinTemp">
                          Min Temperature
                        </Select.Option>
                        <Select.Option value="batteryMaxTemp">
                          Max Temperature
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Braking">
                        <Select.Option value="brakeDuration">
                          Duration
                        </Select.Option>
                        <Select.Option value="brakeDistance">
                          Distance
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.Option value="stopsDuration">
                        Stops Duration
                      </Select.Option>
                      <Select.Option value="energyMetrics">
                        Energy Metrics
                      </Select.Option>
                      <Select.OptGroup label="Acceleration">
                        <Select.Option value="AccelerationDuration">
                          Duration
                        </Select.Option>
                        <Select.Option value="AccelerationDistance">
                          Distance
                        </Select.Option>
                      </Select.OptGroup>
                      <Select.OptGroup label="Deceleration">
                        <Select.Option value="DecelerationDuration">
                          Duration
                        </Select.Option>
                        <Select.Option value="DecelerationDistance">
                          Distance
                        </Select.Option>
                      </Select.OptGroup>
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={[16, 16]} align={"middle"}>
                  <Col>
                    <Tooltip title="Time Series Chart">
                      <LineChartOutlined
                        style={{
                          fontSize: "24px",
                          color: chartType?.timeSeries ? "#f16521" : "#ababab",
                          cursor:
                            !chartType?.histogram && !chartType?.boxPlot
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() =>
                          !chartType?.histogram && !chartType?.boxPlot
                            ? null
                            : updateChartType(
                                "timeSeries",
                                !chartType.timeSeries
                              )
                        }
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip title="Histogram Chart">
                      <BarChartOutlined
                        style={{
                          fontSize: "24px",
                          color: chartType?.histogram ? "#f16521" : "#ababab",
                          cursor:
                            !chartType?.timeSeries && !chartType?.boxPlot
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() =>
                          !chartType?.timeSeries && !chartType?.boxPlot
                            ? null
                            : updateChartType("histogram", !chartType.histogram)
                        }
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip title="Box Plot Chart">
                      <BoxPlotOutlined
                        style={{
                          fontSize: "24px",
                          color: chartType?.boxPlot ? "#f16521" : "#ababab",
                          cursor:
                            !chartType?.timeSeries && !chartType?.histogram
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() =>
                          !chartType?.timeSeries && !chartType?.histogram
                            ? null
                            : updateChartType("boxPlot", !chartType.boxPlot)
                        }
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        >
          {compareValue.map((chart, index) => (
            <Row key={index} gutter={[16, 16]} className="mt05">
              {chartType?.timeSeries ? (
                <Col xs={24} sm={24} md={24} flex={3}>
                  {vehicleDetails?.imeiNumber && (
                    <Iframe
                      title="Battery TimeSeries"
                      src={`${baseURL}?orgId=${
                        userDetails?.currentOrganisation?.id
                      }&var-imei=${
                        vehicleDetails?.imeiNumber
                      }&var-interval=${getGrafanaInterval(
                        startTime,
                        stopTime
                      )}&from=${startTime}&to=${stopTime}&panelId=${
                        TEST_RIDE_SUMMARY.PANEL_ID[chart]["timeSeries"]
                      }`}
                      width="100%"
                      height="300"
                    />
                  )}
                </Col>
              ) : null}
              {chartType?.histogram ? (
                <Col xs={24} sm={24} md={24} flex={"auto"}>
                  {vehicleDetails?.imeiNumber && (
                    <Iframe
                      title="Battery Histogram"
                      src={`${baseURL}?orgId=${
                        userDetails?.currentOrganisation?.id
                      }&var-imei=${
                        vehicleDetails?.imeiNumber
                      }&var-interval=${getGrafanaInterval(
                        startTime,
                        stopTime
                      )}&from=${startTime}&to=${stopTime}&panelId=${
                        TEST_RIDE_SUMMARY.PANEL_ID[chart].histogram
                      }`}
                      width="100%"
                      height="300"
                    />
                  )}
                </Col>
              ) : null}
              {chartType?.boxPlot ? (
                <Col xs={24} sm={24} md={24} flex={"auto"}>
                  {vehicleDetails?.imeiNumber && (
                    <Iframe
                      title="Battery Boxplot"
                      src={`${baseURL}?orgId=${
                        userDetails?.currentOrganisation?.id
                      }&var-imei=${
                        vehicleDetails?.imeiNumber
                      }&var-interval=${getGrafanaInterval(
                        startTime,
                        stopTime
                      )}&from=${startTime}&to=${stopTime}&panelId=${
                        TEST_RIDE_SUMMARY.PANEL_ID[chart].boxPlot
                      }`}
                      width="100%"
                      height="300"
                    />
                  )}
                </Col>
              ) : null}
            </Row>
          ))}
        </Card>
      </Col>
    </Row>
  );
};

export default GrafanaCompare;
