import { Card, Col, Empty, Row, Table } from "antd";
import { useSelector } from "react-redux";
import ECO from "../../../../assets/images/icons/eco.svg";
import CITY from "../../../../assets/images/icons/city.svg";
import POWER from "../../../../assets/images/icons/power.svg";
import OVERALL from "../../../../assets/images/icons/overall.svg";
import { UNITS } from "../../../Common/Constants";
import Iframe from "../../../Common/Iframe";
import { grafanaBaseUrl } from "../../../Common/endpoints";

const SpeedMap = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  const geo_src = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=11`;
  const overallRowData = tripSummary?.tripModeStats?.filter(
    (item) => item.mode === "OVERALL"
  );
  const excludedOverallData = tripSummary?.tripModeStats?.filter(
    (item) => item.mode !== "OVERALL"
  );

  const renderIcons = (mode) => {
    switch (mode) {
      case "ECO":
        return <img src={ECO} alt="ECO" className="mr05" />;
      case "CITY":
        return <img src={CITY} alt="CITY" className="mr05" />;
      case "POWER":
        return <img src={POWER} alt="POWER" className="mr05" />;
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
      render: (_, record) => {
        return (
          <div className="left">
            <div className="flex-center">
              {renderIcons(record.mode)}
              {record.mode}
            </div>
          </div>
        );
      },
    },
    {
      title: "Frequent Speed",
      dataIndex: "speed",
      key: "speed",
      render: (_, record) => {
        return `${record.speed} ${UNITS.KM_PER_HR}`;
      },
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      render: (_, record) => {
        return `${record.distance} ${UNITS.KM}`;
      },
    },
  ];

  const overallImageText = () => {
    return (
      <div className="flex-center">
        <img src={OVERALL} alt="OVERALL" className="mr05" /> OVERALL
      </div>
    );
  };

  return (
    <Row gutter={[16, 16]} className="mt1">
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Row>
          <Col span={24}>
            <Card
              title="Driving Modes"
              bodyStyle={{ padding: "1px 1px", height: '170px' }}
              actions={
                overallRowData && [
                  overallImageText(),
                  `${overallRowData[0]?.speed.toFixed(2)} ${UNITS.KM_PER_HR}`,
                  `${overallRowData[0]?.distance.toFixed(2)} ${UNITS.KM}`,
                ]
              }
            >
              {excludedOverallData?.length > 0 ? (
                <Table
                  className="mode-table"
                  rowKey={(record) => record.mode}
                  columns={columns}
                  dataSource={excludedOverallData}
                  pagination={false}
                  scroll={{ x: "100%" }}
                />
              ) : (
                <div className="pd2015">
                  <Empty />
                </div>
              )}
            </Card>
          </Col>
        </Row>
        
      </Col>
      <Col xs={24} sm={24} md={24} lg={14} xl={14}>
        {vehicleDetails?.imeiNumber && (
          <Iframe title="Geo" src={geo_src} width="100%" height="265" />
        )}
      </Col>
    </Row>
  );
};

export default SpeedMap;
