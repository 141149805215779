import { Col, Empty, Modal, Row } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setShowLoader } from "../../../reducers/loaderSlice";
import { UNITS } from "../../Common/Constants";
import { formattedStringForDropdown } from "../../Common/Utils";

const ListModal = ({
  imeiData,
  showDetailModal,
  selectedItemType,
  imeiListModalOpen,
  setImeiListModalOpen,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (imeiListModalOpen) {
      dispatch(setShowLoader(false));
    }
  }, [dispatch, imeiListModalOpen]);

  const onCancelHandler = () => {
    setImeiListModalOpen(false);
    dispatch(setShowLoader(false));
  };

  const getUnits = () => {
    switch (selectedItemType) {
      case "MCS_TEMPERATURE":
        return UNITS.CELCIOUS;
      case "MOTOR_TEMPERATURE":
        return UNITS.CELCIOUS;
      case "DC_CURRENT":
        return UNITS.AMPERE;
      case "DC_VOLTAGE":
        return UNITS.VOLTS;
      case "SENSOR":
        return UNITS.VOLTS;
      default:
        return "";
    }
  };

  return (
    <Modal
      title={`${formattedStringForDropdown(selectedItemType)} List`}
      open={imeiListModalOpen}
      onCancel={() => onCancelHandler()}
      footer={[]}
    >
      <Row>
        <Col span={24} className="mt1">
          <ul className="ul">
            {Object.keys(imeiData).length === 0 ? (
              <Empty />
            ) : (
              imeiData.map((item, index) => {
                return (
                  <li
                    className="anchor-list cursor-pointer"
                    onClick={() => showDetailModal(item)}
                    key={index}
                  >
                    <Row className="flex-center">
                      <Col span={16} className="f13 bold">
                        {item.imei}
                      </Col>
                      <Col span={6} className="justify-right">
                        <span className="head mr025">{item.value}</span>
                        <span className="mt01 grey">{getUnits()}</span>
                      </Col>
                    </Row>
                  </li>
                );
              })
            )}
          </ul>
        </Col>
      </Row>
    </Modal>
  );
};

export default ListModal;
