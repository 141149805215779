import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ORG_TYPES, ROLES, ROUTES, WARNINGS } from "../Common/Constants";
import { redirectToRelatedPage } from "../Common/Utils";
import { useOrganization } from "../../OrganizationProvider";
import jwtDecode from "jwt-decode";
import Iframe from "../Common/Iframe";
import { setDealerLayout } from "../../reducers/authSlice";
import { useDispatch } from "react-redux";
import { grafanaBaseUrl } from "../Common/endpoints";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = localStorage.getItem("authToken");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const organization = useOrganization();
  const decodedJwt = localStorage.getItem("authToken")
    ? jwtDecode(localStorage.getItem("authToken"))
    : "";

  useEffect(() => {
    dispatch(
      setDealerLayout(
        userDetails?.currentOrganisation?.type === ORG_TYPES.DEALER
      )
    );
    document.title =
      userDetails?.currentOrganisation?.name || organization?.name;

    if (userDetails?.currentRole === ROLES.RIDER) {
      navigate(ROUTES.LOGOUT.link);
      toast.warning(WARNINGS.RIDER_LOGIN);
    } else {
      setTimeout(function () {
        navigate(redirectToRelatedPage());
      }, 2000);
    }
  }, [dispatch, navigate, organization, userDetails]);

  return (
    decodedJwt !== "" && (
      <Iframe
        title="Login"
        src={`${grafanaBaseUrl}/login?token=${authToken}`}
        width="0"
        height="0"
      />
    )
  );
};

export default Dashboard;
