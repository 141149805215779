import { Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import "./style.css";
import DaysPicker from "../Common/DaysPicker";
import Overview from "./Overview";
import Temperature from "./Temperature";
import Stack from "./Stack";
import {
  executiveImeiDataUrl,
  executiveMetaDataUrl,
  executiveSparklingDataUrl,
  executiveStackDataUrl,
} from "../Common/endpoints";
import { getApis, postApis } from "../Common/apis";
import { useSelector } from "react-redux";
import { getGrafanaInterval } from "../Common/Utils";
import ListModal from "./Modals/List";
import DetailsModal from "./Modals/Details";

const Home = () => {
  const [metaData, setMetaData] = useState({});
  const [sparklingData, setSparklingData] = useState(null);
  const [stackData, setStackData] = useState(null);
  const authToken = localStorage.getItem("authToken");
  const startTime = useSelector((state) => state.filter.startTime);
  const endTime = useSelector((state) => state.filter.endTime);
  const [imeiListModalOpen, setImeiListModalOpen] = useState(false);
  const [imeiData, setImeiData] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemType, setSelectedItemType] = useState(null);

  const showTemperatureModal = async (filter, sort, sensorId) => {
    const payload = sensorId
      ? {
          filter: filter,
          sortDirection: sort,
          startTime: startTime,
          endTime: endTime,
          sensorId: sensorId,
        }
      : {
          filter: filter,
          sortDirection: sort,
          startTime: startTime,
          endTime: endTime,
        };
    await postApis(executiveImeiDataUrl, payload, authToken)
      .then((res) => {
        if (res.status === 200) {
          setImeiData(res?.data);
          setImeiListModalOpen(true);
          setSelectedItemType(filter);
        }
      })
      .catch((e) => console.log(e));
  };

  const showDetailModal = (item) => {
    setSelectedItem(item);
    setDetailModalOpen(true);
    setImeiListModalOpen(false);
  };

  const getMetaData = useCallback(async () => {
    await getApis(executiveMetaDataUrl, {}, authToken)
      .then((response) => {
        setMetaData(response?.data);
      })
      .catch((err) => console.log(err));
  }, [authToken]);

  const getSparklineData = useCallback(async () => {
    await getApis(
      executiveSparklingDataUrl,
      {
        from: startTime,
        to: endTime,
        interval: getGrafanaInterval(startTime, endTime),
      },
      authToken
    )
      .then((response) => {
        setSparklingData(response?.data);
      })
      .catch((err) => console.log(err));
  }, [authToken, endTime, startTime]);

  const getStackData = useCallback(async () => {
    await getApis(
      executiveStackDataUrl,
      {
        from: startTime,
        to: endTime,
      },
      authToken
    )
      .then((response) => {
        setStackData(response?.data);
      })
      .catch((err) => console.log(err));
  }, [authToken, endTime, startTime]);

  const fetchData = useCallback(() => {
    if (startTime && endTime) {
      getMetaData();
      getSparklineData();
      getStackData();
    }
  }, [endTime, getMetaData, getSparklineData, getStackData, startTime]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [fetchData]);

  return (
    <Row>
      <Col span={24}>
        <ListModal
          imeiData={imeiData}
          showDetailModal={showDetailModal}
          selectedItemType={selectedItemType}
          imeiListModalOpen={imeiListModalOpen}
          setImeiListModalOpen={setImeiListModalOpen}
        />

        <DetailsModal
          selectedItem={selectedItem}
          detailModalOpen={detailModalOpen}
          setDetailModalOpen={setDetailModalOpen}
          selectedItemType={selectedItemType}
        />

        <Overview metaData={metaData} />

        <div className="shadow-card mt1">
          <Row gutter={[16, 16]} className="flex-center">
            <Col xs={24} sm={24} md={24} lg={24} xl={6} className="head18">
              Product Health Dashboard
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={18}>
              <div className="float-right">
                <DaysPicker callApis={fetchData} />
              </div>
            </Col>
          </Row>

          <Temperature
            sparklingData={sparklingData}
            stackData={stackData}
            authToken={authToken}
            showTemperatureModal={showTemperatureModal}
          />
          <Stack
            showModal={showTemperatureModal}
            sparklingData={sparklingData}
            stackData={stackData}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Home;
