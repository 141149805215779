import { Col, Modal, Row } from "antd";
import BatteryPanels from "../BatteryPanels";
import MotorPanels from "../MotorPanels";

const DetailsModal = ({
  selectedItem,
  detailModalOpen,
  setDetailModalOpen,
  selectedItemType,
}) => {
  return (
    <Modal
      title={selectedItem?.imei}
      open={detailModalOpen}
      onCancel={() => setDetailModalOpen(false)}
      footer={[]}
      width={1200}
    >
      <Row>
        <Col span={24}>
          {selectedItemType === "SENSOR" ? (
            <BatteryPanels imei={selectedItem?.imei} />
          ) : (
            <MotorPanels
              imei={selectedItem?.imei}
              selectedItemType={selectedItemType}
            />
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default DetailsModal;
