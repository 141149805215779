import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsSankey from "highcharts/modules/sankey";
import HighchartsDependencyWheel from "highcharts/modules/dependency-wheel";
import { useEffect, useState } from "react";
import { getScreenType } from "../../Common/Utils";
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);
HighchartsSankey(Highcharts);
HighchartsDependencyWheel(Highcharts);

const ChordChart = ({ summaryList, tripSummary }) => {
  const [screenType, setScreenType] = useState(getScreenType());
  const [chartData, setChartData] = useState([]);
  const summaryCount = summaryList?.length;

  useEffect(() => {
    const data = tripSummary?.chordData;
    if (data?.length > 0) {
      const values = data.map((value, index) => [
        value.startCity,
        value.endCity,
        value.noOfTrip,
      ]);

      setChartData(values);
    }
  }, [tripSummary]);

  useEffect(() => {
    setScreenType(getScreenType());
  }, [summaryCount]);

  const getOptions = (type) => ({
    chart: {
      type,
      width:
        ["xxs", "xs", "sm"].includes(screenType) ||
        (summaryCount === 2 && ["md", "lg"].includes(screenType))
          ? 360
          : 580,
      height:
        ["xxs", "xs", "sm"].includes(screenType) ||
        (summaryCount === 2 && ["md", "lg"].includes(screenType))
          ? 420
          : 600,
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        dataLabels: {
          enabled: true,
          align: "center",
          allowOverlap: true,
          color: "#333",
          style: {
            textOutline: "none",
          },
          useHTML: chartData?.length === 1,
          verticalAlign: "top",
          textPath: {
            enabled: true,
            attributes: {
              dy: 5,
            },
          },
          distance: 10,
        },
        size: "95%",
        keys: ["from", "to", "weight"],
        data: chartData,
      },
    ],
    credits: {
      enabled: false,
    },
  });

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getOptions("dependencywheel")}
    />
  );
};

export default ChordChart;
