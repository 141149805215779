import { Col, Progress, Row } from "antd";
import FULLSTAR from "../../../assets/images/icons/full-star.svg";

const Ratings = () => {
  return (
    <Row gutter={[16, 16]} className="mt1">
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Row justify={"space-evenly"} align={"middle"}>
          <Col className="w25">Battery</Col>
          <Col flex={"auto"}>
            <Progress
              percent={70}
              showInfo={false}
              strokeColor={"#FADB14"}
              className="w50 m0"
            />
          </Col>
          <Col>
            <img src={FULLSTAR} alt="start" className="ml05" />
          </Col>
          <Col span={2} className="ml05">
            3.5
          </Col>
        </Row>
        <Row justify={"space-evenly"} align={"middle"}>
          <Col className="w25">Battery</Col>
          <Col flex={"auto"}>
            <Progress
              percent={60}
              showInfo={false}
              strokeColor={"#FADB14"}
              className="w50 m0"
            />
          </Col>
          <Col>
            <img src={FULLSTAR} alt="start" className="ml05" />
          </Col>
          <Col span={2} className="ml05">
            3
          </Col>
        </Row>
        <Row justify={"space-evenly"} align={"middle"}>
          <Col className="w25">xxxxx</Col>
          <Col flex={"auto"}>
            <Progress
              percent={50}
              showInfo={false}
              strokeColor={"#FADB14"}
              className="w50 m0"
            />
          </Col>
          <Col>
            <img src={FULLSTAR} alt="start" className="ml05" />
          </Col>
          <Col span={2} className="ml05">
            4.5
          </Col>
        </Row>
        <Row justify={"space-evenly"} align={"middle"}>
          <Col className="w25">xxxxx</Col>
          <Col flex={"auto"}>
            <Progress
              percent={45}
              showInfo={false}
              strokeColor={"#FADB14"}
              className="w50 m0"
            />
          </Col>
          <Col>
            <img src={FULLSTAR} alt="start" className="ml05" />
          </Col>
          <Col span={2} className="ml05">
            2.5
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Row justify={"space-evenly"} align={"middle"}>
          <Col className="w25">xxxxx</Col>
          <Col flex={"auto"}>
            <Progress
              percent={60}
              showInfo={false}
              strokeColor={"#FADB14"}
              className="w50 m0"
            />
          </Col>
          <Col>
            <img src={FULLSTAR} alt="start" className="ml05" />
          </Col>
          <Col span={2} className="ml05">
            3
          </Col>
        </Row>
        <Row justify={"space-evenly"} align={"middle"}>
          <Col className="w25">xxxxx</Col>
          <Col flex={"auto"}>
            <Progress
              percent={80}
              showInfo={false}
              strokeColor={"#FADB14"}
              className="w50 m0"
            />
          </Col>
          <Col>
            <img src={FULLSTAR} alt="start" className="ml05" />
          </Col>
          <Col span={2} className="ml05">
            4
          </Col>
        </Row>

        <Row justify={"space-evenly"} align={"middle"}>
          <Col className="w25">xxxxx</Col>
          <Col flex={"auto"}>
            <Progress
              percent={40}
              showInfo={false}
              strokeColor={"#FADB14"}
              className="w50 m0"
            />
          </Col>
          <Col>
            <img src={FULLSTAR} alt="start" className="ml05" />
          </Col>
          <Col span={2} className="ml05">
            2
          </Col>
        </Row>

        <Row justify={"space-evenly"} align={"middle"}>
          <Col className="w25">xxxxx</Col>
          <Col flex={"auto"}>
            <Progress
              percent={50}
              showInfo={false}
              strokeColor={"#FADB14"}
              className="w50 m0"
            />
          </Col>
          <Col>
            <img src={FULLSTAR} alt="start" className="ml05" />
          </Col>
          <Col span={2} className="ml05">
            2.4
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Ratings;
