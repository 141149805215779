import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoggedOut } from "../../reducers/authSlice";
import { ROUTES } from "./Constants";
import { grafanaBaseUrl } from "./endpoints";
import Iframe from "./Iframe";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    dispatch(setLoggedOut());
    navigate(ROUTES.LOGIN);
  }, [dispatch, navigate]);

  return (
    <Iframe
      title="Logout"
      src={`${grafanaBaseUrl}/logout`}
      width="0"
      height="0"
    />
  );
};

export default Logout;
