import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { setShowLoader } from "../../reducers/loaderSlice";
import Loader from "../Common/Loader";

const TempCardLayout = ({
  header,
  unit,
  filter,
  belowRange,
  aboveRange,
  sparklingData,
  minValue,
  maxValue,
  sparkColor,
  showDescTemperatureModal,
  showAscTemperatureModal,
}) => {
  const dispatch = useDispatch();
  const showLoader = useSelector((state) => state.loader.show);

  const onClickBelowRangeHandler = (filter, sort) => {
    showDescTemperatureModal(filter, sort);
    dispatch(setShowLoader(true));
  };

  const onClickAboveRangeHandler = (filter, sort) => {
    showAscTemperatureModal(filter, sort);
    dispatch(setShowLoader(true));
  };

  return (
    <>
      {showLoader && <Loader noText={true} />}

      <div className="card">
        <Row>
          <Col span={24} className="text-center mt1">
            <div className="flex-center">
              <span className="normal-head">{header}</span>
              <span className="mt025 f12 ml025">{unit}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="space-around" className="mt05 pd1">
          <Col span={10} className="grey-box">
            <div className="text-center">
              <div className="f12 grey">Below Range</div>
              <div className="head24 mt05">{belowRange}%</div>
              <div
                className="link"
                onClick={() => onClickBelowRangeHandler(filter, "desc")}
              >
                Vehicles
              </div>
            </div>
          </Col>
          <Col span={10} className="grey-box">
            <div className="text-center">
              <div className="f12 grey">Above Range</div>
              <div className="head24 mt05">{aboveRange}%</div>
              <div
                className="link"
                onClick={() => onClickAboveRangeHandler(filter, "asc")}
              >
                Vehicles
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="flex-center">
            <Sparklines data={sparklingData} margin={10}>
              <SparklinesLine color={sparkColor} />
            </Sparklines>
          </Col>
        </Row>

        <Row className="grey-footer mt05 text-center">
          <Col span={12}>
            <div className="f12 grey">Min</div>
            <div className="head18">{minValue}</div>
          </Col>
          <Col span={12}>
            <div className="f12 grey">Max</div>
            <div className="head18">{maxValue}</div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TempCardLayout;
