import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";

const Display = ({ selectedTime, handleButtonClick, nextDisable }) => {
  const startTime = useSelector((state) => new Date(state.filter.startTime));
  const endTime = useSelector((state) => new Date(state.filter.endTime));

  return (
    <div className="date flex-center">
      <Row gutter={[16, 16]}>
        <Col span={2} className="flex-center">
          <div
            onClick={() => handleButtonClick(true)}
            className="cursor-pointer"
          >
            <LeftOutlined className="f14" />
          </div>
        </Col>
        <Col span={18} className="flex-center">
          <div className="f14">
            {selectedTime === "Hour"
              ? `${startTime.toLocaleTimeString([], {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })} - ${endTime.toLocaleTimeString([], {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })}`
              : `${startTime.toLocaleString("default", {
                  month: "short",
                })} ${startTime.getDate()} - ${endTime.toLocaleString(
                  "default",
                  {
                    month: "short",
                  }
                )} ${endTime.getDate()}`}
          </div>
        </Col>
        <Col span={2} className="flex-center">
          {nextDisable ? (
            <div>
              <RightOutlined className="f14 disabled" />
            </div>
          ) : (
            <div
              onClick={() => handleButtonClick(false)}
              className="cursor-pointer"
            >
              <RightOutlined className="f14" />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Display;
