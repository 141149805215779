import { Col, Row, Tabs, Divider } from "antd";
import { useSelector } from "react-redux";
import { getGrafanaInterval } from "../../../../Common/Utils";
import ACCELERATION from "../../../../../assets/images/icons/acceleration-distance.svg";
import { useState } from "react";
import AddToCompare from "../addToCompare";
import Iframe from "../../../../Common/Iframe";
import { TEST_RIDE_SUMMARY } from "../../../../Common/Constants";
import FullGraphsModal from "./fullGraphsModal";
import CardCollapse from "../../../../Common/CardCollapse";

const AccelerationAnalytics = ({
  baseURL,
  compareValue,
  updateCompareValue,
}) => {
  const [selectedTab, setSelectedTab] = useState("0-20");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  const onChangeTab = (value) => {
    setSelectedTab(value);
  };

  const items = [
    {
      key: "0-20",
      label: "0-20",
    },
    {
      key: "0-40",
      label: "0-40",
    },
    {
      key: "0-60",
      label: "0-60",
    },
    {
      key: "0-80",
      label: "0-80",
    },
  ];

  const subContent = (
    <Row gutter={[16, 16]} className="mt05">
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        Max: {tripSummary?.maxAcceleration}
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        Min: {tripSummary?.minAcceleration}
      </Col>
    </Row>
  );

  return (
    <CardCollapse
      cardClassName={"ride-statistic-analytics-card"}
      title={"Acceleration"}
      icon={ACCELERATION}
      subContent={subContent}
    >
      <Row className="mt1 pd0">
        <Col span={24}>
          <Tabs
            activeKey={selectedTab}
            items={items}
            type="card"
            onChange={onChangeTab}
            className="celeration-tab-view"
            tabBarExtraContent={
              <FullGraphsModal
                modalTitle="Acceleration"
                vehicleDetails={vehicleDetails}
                baseURL={baseURL}
                chartList={["AccelerationDistance", "AccelerationDuration"]}
                chartQuery={`&var-accl_range=${selectedTab}`}
                startTime={tripSummary?.rideForm?.startTime}
                stopTime={tripSummary?.rideForm?.stopTime}
              />
            }
          />
          <Row className="pd0">
            <Col span={24}>
              {vehicleDetails?.imeiNumber && (
                <Row gutter={[12, 12]} className="pdtb05">
                  <Col span={24}>
                    <Iframe
                      title={"Time Series - Acceleration Distance"}
                      src={`${baseURL}?orgId=${
                        userDetails?.currentOrganisation?.id
                      }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
                        tripSummary?.rideForm?.testId
                      }&var-interval=${getGrafanaInterval(
                        tripSummary?.rideForm?.startTime,
                        tripSummary?.rideForm?.stopTime
                      )}&var-accl_range=${selectedTab}&from=${
                        tripSummary?.rideForm?.startTime
                      }&to=${tripSummary?.rideForm?.stopTime}&panelId=${
                        TEST_RIDE_SUMMARY.PANEL_ID["AccelerationDistance"][
                          "timeSeries"
                        ]
                      }`}
                      width="100%"
                      height="240"
                    />
                  </Col>
                </Row>
              )}
              <Row className="mt1 mb1">
                <Col className="add-compare-col">
                  <AddToCompare
                    value={"AccelerationDistance"}
                    compareValue={compareValue}
                    updateCompareValue={updateCompareValue}
                  />
                </Col>
              </Row>

              <Row>
                <Divider
                  style={{
                    background: "#eee",
                    height: "4px",
                    margin: "12px 0",
                  }}
                />
              </Row>
              {vehicleDetails?.imeiNumber && (
                <Row gutter={[12, 12]} className="pdtb05 mt1">
                  <Col span={24}>
                    <Iframe
                      title={"Time Series - Acceleration Duration"}
                      src={`${baseURL}?orgId=${
                        userDetails?.currentOrganisation?.id
                      }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
                        tripSummary?.rideForm?.testId
                      }&var-interval=${getGrafanaInterval(
                        tripSummary?.rideForm?.startTime,
                        tripSummary?.rideForm?.stopTime
                      )}&var-accl_range=${selectedTab}&from=${
                        tripSummary?.rideForm?.startTime
                      }&to=${tripSummary?.rideForm?.stopTime}&panelId=${
                        TEST_RIDE_SUMMARY.PANEL_ID["AccelerationDuration"][
                          "timeSeries"
                        ]
                      }`}
                      width="100%"
                      height="240"
                    />
                  </Col>
                </Row>
              )}
              <Row className="mt1 mb1">
                <Col className="add-compare-col">
                  <AddToCompare
                    value={"AccelerationDuration"}
                    compareValue={compareValue}
                    updateCompareValue={updateCompareValue}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardCollapse>
  );
};

export default AccelerationAnalytics;
