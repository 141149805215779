import { Col, Row, Card } from "antd";
import { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const CardCollapse = ({ children, subContent, cardClassName, icon, title }) => {
  const [view, setView] = useState(false);

  const onViewChange = () => {
    setView(!view);
  };

  return (
    <Card
      className={cardClassName}
      title={
        <Row justify={"space-between"} onClick={onViewChange}>
          <Col className="flex-center">
            <img src={icon} alt="stops" className="icon-size mr05" />
            {title}
          </Col>
          <Col className="font-weight-normal">
            {view ? (
              <>
                Hide
                <UpOutlined className="ml05" />
              </>
            ) : (
              <>
                Show
                <DownOutlined className="ml05" />
              </>
            )}
          </Col>
        </Row>
      }
    >
      {subContent}
      {view ? children : null}
    </Card>
  );
};

export default CardCollapse;
