import { Col, Row } from "antd";
import TempCardLayout from "./TempCardLayout";

const Temperature = ({ sparklingData, stackData, showTemperatureModal }) => {
  return (
    <Row gutter={[16, 16]} className="mt2">
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <TempCardLayout
          header="DC Current"
          unit="(A)"
          filter="DC_CURRENT"
          sparkColor="#59b200"
          belowRange={stackData?.data?.dcCurrent?.belowRange}
          aboveRange={stackData?.data?.dcCurrent?.aboveRange}
          sparklingData={sparklingData?.dcCurrent}
          minValue={stackData?.maxMinData?.minDcCurrent}
          maxValue={stackData?.maxMinData?.maxDcCurrent}
          avgValue={stackData?.maxMinData?.avgDcCurrent}
          showDescTemperatureModal={() =>
            showTemperatureModal("DC_CURRENT", "desc")
          }
          showAscTemperatureModal={() =>
            showTemperatureModal("DC_CURRENT", "asc")
          }
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <TempCardLayout
          header="DC Voltage"
          unit="(V)"
          filter="DC_VOLTAGE"
          sparkColor="#b20000"
          belowRange={stackData?.data?.dcVoltage?.belowRange}
          aboveRange={stackData?.data?.dcVoltage?.aboveRange}
          sparklingData={sparklingData?.dcVoltage}
          minValue={stackData?.maxMinData?.minDcVoltage}
          maxValue={stackData?.maxMinData?.maxDcVoltage}
          avgValue={stackData?.maxMinData?.avgDcVoltage}
          showDescTemperatureModal={() =>
            showTemperatureModal("DC_VOLTAGE", "desc")
          }
          showAscTemperatureModal={() =>
            showTemperatureModal("DC_VOLTAGE", "asc")
          }
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <TempCardLayout
          header="MCS Temperature"
          unit="(C)"
          filter="MCS_TEMPERATURE"
          sparkColor="#0000b2"
          belowRange={stackData?.data?.mcsTemp?.belowRange}
          aboveRange={stackData?.data?.mcsTemp?.aboveRange}
          sparklingData={sparklingData?.mcsTemp}
          minValue={stackData?.maxMinData?.minMcsTemp}
          maxValue={stackData?.maxMinData?.maxMcsTemp}
          avgValue={stackData?.maxMinData?.avgMcsTemp}
          showDescTemperatureModal={() =>
            showTemperatureModal("MCS_TEMPERATURE", "desc")
          }
          showAscTemperatureModal={() =>
            showTemperatureModal("MCS_TEMPERATURE", "asc")
          }
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <TempCardLayout
          header="Motor Temperature"
          unit="(C)"
          filter="MOTOR_TEMPERATURE"
          sparkColor="#ff7f00"
          belowRange={stackData?.data?.motorTemp?.belowRange}
          aboveRange={stackData?.data?.motorTemp?.aboveRange}
          sparklingData={sparklingData?.motorTemp}
          minValue={stackData?.maxMinData?.minMotorTemp}
          maxValue={stackData?.maxMinData?.maxMotorTemp}
          avgValue={stackData?.maxMinData?.avgMotorTemp}
          showDescTemperatureModal={() =>
            showTemperatureModal("MOTOR_TEMPERATURE", "desc")
          }
          showAscTemperatureModal={() =>
            showTemperatureModal("MOTOR_TEMPERATURE", "asc")
          }
        />
      </Col>
    </Row>
  );
};

export default Temperature;
