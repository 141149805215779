import { Card, Divider } from "antd";
import { useSelector } from "react-redux";
import HIGHLIGHT from "../../../../assets/images/icons/highlight.svg";
import { UNITS } from "../../../Common/Constants";
import "./style.css";

const TripHighlight = () => {
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  const innerContent = (title, count, unit, className) => {
    return className ? (
      <div className={className}>
        <div className="fs12 mt1">{title}</div>
        <div className="mt1 mb025">
          <span className="avg-count">{count}</span>
          <span className="ml015">{unit}</span>
        </div>
      </div>
    ) : (
      <div>
        <div className="fs12 mt1">{title}</div>
        <div className="mt1 mb025">
          <span className="avg-count">{count}</span>
          <span className="card-sub-text ml015">{unit}</span>
        </div>
      </div>
    );
  };

  return (
    <Card
      title={
        <div className="fs14 left">
          <div className="flex-center overview-header">
            <img src={HIGHLIGHT} alt="km" className="icon-size mr05" />
            Highlights
          </div>
        </div>
      }
    >
      <Card.Grid className="grid">
        {innerContent("Top Speed", tripSummary?.topSpeed, UNITS.KM)}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Max Acceleration",
          tripSummary?.maxAcceleration,
          UNITS.KM_PER_HR,
        )}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Min Acceleration",
          tripSummary?.minAcceleration,
          UNITS.KM_PER_HR,
        )}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Max Deceleration",
          tripSummary?.maxDeceleration,
          UNITS.KM_PER_HR,
        )}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Min Deceleration",
          tripSummary?.minDeceleration,
          UNITS.KM_PER_HR,
        )}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Max Current",
          tripSummary?.maxCurrent,
          UNITS.MILLI_AMPERE,
        )}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Min Current",
          tripSummary?.minCurrent,
          UNITS.MILLI_AMPERE,
        )}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent("Braking Time", tripSummary?.brakingTime, UNITS.SECONDS)}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Braking Distance",
          tripSummary?.brakingDistance,
          UNITS.METER
        )}
      </Card.Grid>
      <Card.Grid className="grid">
        {innerContent(
          "Energy KM",
          tripSummary?.energyPerKm,
          UNITS.MILLI_AMPERE,
        )}
      </Card.Grid>
    </Card>
  );
};
export default TripHighlight;
