import { Col, Row, Progress, Segmented, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setDealerLayout,
  setDealerSlideShow,
} from "../../../reducers/authSlice";
import VEHICLE from "../../../assets/images/dealer/model.svg";
import MOTOR from "../../../assets/images/dealer/motor.svg";
import SHOCKER from "../../../assets/images/dealer/shocker.svg";
import CAR from "../../../assets/images/dealer/car.svg";
import UP from "../../../assets/images/dealer/arrowUp.svg";
import DOWN from "../../../assets/images/dealer/arrowDown.svg";
import Chart from "react-apexcharts";

const Display = () => {
  const [filter, setFilter] = useState("Weekly");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDealerLayout(true));
    dispatch(setDealerSlideShow(false));
  }, [dispatch]);

  const servicesCompleted = [
    {
      id: 1,
      serviceName: "Squid",
      percentageCompleted: 80,
    },
    {
      id: 2,
      serviceName: "Squid Pro",
      percentageCompleted: 20,
    },
    {
      id: 3,
      serviceName: "EC2",
      percentageCompleted: 60,
    },
    {
      id: 4,
      serviceName: "EC3",
      percentageCompleted: 35,
    },
    {
      id: 5,
      serviceName: "Test",
      percentageCompleted: 50,
    },
    {
      id: 5,
      serviceName: "Test",
      percentageCompleted: 50,
    },
    {
      id: 5,
      serviceName: "Test",
      percentageCompleted: 50,
    },
  ];

  const partsWarranty = [
    {
      id: 1,
      image: MOTOR,
      name: "MOTOR",
      claims: 100,
      percentageChanages: 50,
      change: DOWN,
    },
    {
      id: 2,
      image: SHOCKER,
      name: "SHOCKER",
      claims: 50,
      percentageChanages: 10,
      change: UP,
    },
    {
      id: 3,
      image: CAR,
      name: "Battery",
      claims: 100,
      percentageChanages: 50,
      change: DOWN,
    },
  ];

  const serviceBookings = [
    { id: 1, name: "SQD Ultra - 3" },
    { id: 2, name: "Squid Pro - 2" },
    { id: 3, name: "Squid Pro - 2" },
    { id: 3, name: "Squid Pro - 2" },
    { id: 3, name: "Squid Pro - 2" },
    { id: 3, name: "Squid Pro - 2" },
  ];

  const vehiclePerformancesData = {
    series: [
      {
        name: "Initial",
        data: [49, 43, 38, 40, 33],
      },
      {
        name: "Final",
        data: [44, 40, 30, 24, 28],
      },
    ],
    options: {
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Squid", "Squid Pro", "EC2", "EC2 Pro", "EC3"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  const customerRatingsData = {
    series: [
      {
        name: "Squid",
        data: [30, 60, 30, 70, 170],
      },
      {
        name: "Squid Pro",
        data: [20, 40, 20, 100, 200],
      },
      {
        name: "EC2",
        data: [40, 90, 120, 120, 240],
      },
      {
        name: "EC2 Pro",
        data: [30, 20, 30, 80, 180],
      },
      {
        name: "EC3",
        data: [36, 25, 35, 85, 190],
      },
    ],
    options: {
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Squid", "Squid Pro", "EC2", "EC2 Pro", "EC3"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Row>
              <Col span={24}>
                <Segmented
                  options={["Weekly", "Monthly"]}
                  value={filter}
                  onChange={(e) => setFilter(e)}
                  size="large"
                />
              </Col>
            </Row>
            <Row className="mt2">
              <Col span={24} className="flex-center">
                <img src={VEHICLE} alt="Scooter" />
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={8} span={4}>
            <div className="dealer-card">
              <div className="dealer-card-head">VEHICLE PERFORMANCES</div>
              <div className="dealer-card-body mnht17">
                <Chart
                  options={vehiclePerformancesData.options}
                  series={vehiclePerformancesData.series}
                  type="bar"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} span={4}>
            <div className="dealer-card">
              <div className="dealer-card-head">SERVICES BOOKING - TODAY</div>
              <div className="dealer-card-body mnht17 scrollable">
                {serviceBookings.map((service, index) => (
                  <>
                    <Row className="mt075">
                      <Col>
                        {service.id}. {service.name}
                      </Col>
                    </Row>
                    {serviceBookings.length - 1 !== index && (
                      <Divider className="hr-margin" />
                    )}
                  </>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt1025" gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <div className="dealer-card">
              <div className="dealer-card-head">CUSTOMER RATINGS BY MODELS</div>
              <div className="dealer-card-body mnht17">
                <Chart
                  options={customerRatingsData.options}
                  series={customerRatingsData.series}
                  type="bar"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <div className="dealer-card">
              <div className="dealer-card-head">PARTS WARRANTY</div>
              <div className="dealer-card-body text-center mnht17 scrollable">
                <Row align="middle" className="parts-warranty-row-head">
                  <Col span={8}>Parts</Col>
                  <Col span={6}>Claims</Col>
                  <Col className="serviceProgress" span={10}>
                    %Changes
                  </Col>
                </Row>
                {partsWarranty.map((part, index) => (
                  <>
                    <Row gutter={[16, 16]} className="mt1">
                      <Col span={8} align="start" className="flex-center">
                        <img
                          src={part.image}
                          alt={part.name}
                          width="30"
                          className="mr05"
                        />
                        <span>{part.name}</span>
                      </Col>
                      <Col span={6} className="flex-center">
                        {part.claims}
                      </Col>
                      <Col span={10} className="flex-center">
                        <span>{part.percentageChanages}%</span>
                        <img
                          src={part.change}
                          alt={part.name}
                          width="15"
                          className="ml025"
                        />
                      </Col>
                    </Row>
                    {partsWarranty.length - 1 !== index && (
                      <Divider className="hr-margin" />
                    )}
                  </>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <div className="dealer-card">
              <div className="dealer-card-head">SERVICES COMPLETED - TODAY</div>
              <div className="dealer-card-body text-center mnht17 scrollable">
                {servicesCompleted.map((service, index) => (
                  <>
                    <Row className="flex-center">
                      <Col span={7} align="start">
                        {service.id}. {service.serviceName}
                      </Col>
                      <Col span={13}>
                        <Progress
                          percent={service.percentageCompleted}
                          showInfo={false}
                        />
                      </Col>
                      <Col className="serviceProgress" span={4}>
                        {service.percentageCompleted}%
                      </Col>
                    </Row>
                    {servicesCompleted.length - 1 !== index && (
                      <Divider className="hr-margin" />
                    )}
                  </>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Display;
