import { Col, Radio, Row, Space } from "antd";
import React, { useState, useEffect, memo } from "react";
import Display from "./Display";
import { setEndTime, setStartTime } from "../../../reducers/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import { isWithinLast7Days } from "../Utils";

const DaysPicker = memo(({ callApis }) => {
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState("Hour");
  const [nextDisable, setNextDisable] = useState(false);
  const startTime = useSelector((state) => state.filter.startTime);
  const endTime = useSelector((state) => state.filter.endTime);
  const [shouldCallApis, setShouldCallApis] = useState(false);
  let updatedStartTime, updatedEndTime;

  const updateDisplayedTime = () => {
    switch (selectedTime) {
      case "Hour":
        dispatch(setEndTime(new Date().getTime()));
        dispatch(setStartTime(new Date().getTime() + -1 * 60 * 60 * 1000));
        isNextDisabled(new Date().getTime());
        break;
      case "Today":
        dispatch(setEndTime(new Date().getTime()));
        dispatch(setStartTime(new Date().getTime() + -1 * 24 * 60 * 60 * 1000));
        isNextDisabled(new Date().getTime());

        break;
      case "Week":
        dispatch(setEndTime(new Date().getTime()));
        dispatch(
          setStartTime(new Date().getTime() + -1 * 7 * 24 * 60 * 60 * 1000)
        );
        isNextDisabled(new Date().getTime());
        break;
      case "Month":
        dispatch(setEndTime(new Date().getTime()));
        dispatch(
          setStartTime(new Date().getTime() + -1 * 30 * 24 * 60 * 60 * 1000)
        );
        isNextDisabled(new Date().getTime());
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    updateDisplayedTime();
    if (shouldCallApis) {
      callApis();
      setShouldCallApis(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime]);

  const handleButtonClick = (decrement) => {
    const timeIncrement = decrement ? -1 : 1;

    switch (selectedTime) {
      case "Hour":
        updatedStartTime = startTime + timeIncrement * 60 * 60 * 1000;
        updatedEndTime = endTime + timeIncrement * 60 * 60 * 1000;
        break;
      case "Today":
        updatedStartTime = startTime + timeIncrement * 24 * 60 * 60 * 1000;
        updatedEndTime = endTime + timeIncrement * 24 * 60 * 60 * 1000;
        break;
      case "Week":
        updatedStartTime = startTime + timeIncrement * 7 * 24 * 60 * 60 * 1000;
        updatedEndTime = endTime + timeIncrement * 7 * 24 * 60 * 60 * 1000;
        break;
      default:
        break;
    }

    isNextDisabled(updatedEndTime);
    dispatch(setStartTime(updatedStartTime));
    dispatch(setEndTime(updatedEndTime));
    setShouldCallApis(true);
  };

  const isNextDisabled = (endTime) => {
    switch (selectedTime) {
      case "Hour":
        return setNextDisable(
          new Date(endTime).getHours() >= new Date().getHours()
        );
      case "Today":
        return setNextDisable(
          new Date(endTime).getDate() >= new Date().getDate()
        );
      case "Week":
        return setNextDisable(
          new Date(endTime).getDate() >= new Date().getDate() &&
            !isWithinLast7Days(new Date(endTime))
        );
      default:
        return setNextDisable(false);
    }
  };

  const onFilterChange = (e) => {
    setSelectedTime(e.target.value);
    callApis();
  };

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Space wrap>
          {["Hour", "Today", "Week"].map((item, index) => (
            <div key={index} className="flex-center">
              <Radio.Group
                options={[item]}
                onChange={(e) => onFilterChange(e)}
                value={selectedTime}
                optionType="button"
                size="large"
                buttonStyle="solid"
              />
              {selectedTime === item && startTime && endTime && (
                <Display
                  selectedTime={selectedTime}
                  handleButtonClick={handleButtonClick}
                  nextDisable={nextDisable}
                />
              )}
            </div>
          ))}
        </Space>
      </Col>
    </Row>
  );
});

export default DaysPicker;
