import { Col, Row, Modal, Card, Tooltip } from "antd";
import {
  BarChartOutlined,
  BoxPlotOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import Iframe from "../../../../Common/Iframe";
import { getGrafanaInterval } from "../../../../Common/Utils";
import { TEST_RIDE_SUMMARY } from "../../../../Common/Constants";
import { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";

const FullGraphsModal = ({
  vehicleDetails,
  modalTitle,
  baseURL,
  chartList,
  chartQuery,
  startTime,
  stopTime,
}) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartType, setChartType] = useState({
    timeSeries: true,
    histogram: false,
    boxPlot: false,
  });

  useEffect(() => {
    setChartType({ timeSeries: true, histogram: false, boxPlot: false });
  }, []);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const updateChartType = (name, value) => {
    setChartType((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div onClick={handleOpen} className="align-center">
        <EyeOutlined className="mr05" />
        Details
      </div>
      <Modal
        title={modalTitle}
        width={"90%"}
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        className="compare-modal"
        footer={null}
      >
        <Row gutter={[16, 16]} className="grafana-compare">
          <Col span={24}>
            <Card
              title={
                <Row gutter={[16, 16]} justify={"end"} className="pd05">
                  <Col>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col>
                        <Tooltip title="Time Series Chart">
                          <LineChartOutlined
                            style={{
                              fontSize: "24px",
                              color: chartType?.timeSeries
                                ? "#f16521"
                                : "#ababab",
                              cursor:
                                !chartType?.histogram && !chartType?.boxPlot
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            onClick={() =>
                              !chartType?.histogram && !chartType?.boxPlot
                                ? null
                                : updateChartType(
                                    "timeSeries",
                                    !chartType.timeSeries
                                  )
                            }
                          />
                        </Tooltip>
                      </Col>
                      <Col>
                        <Tooltip title="Histogram Chart">
                          <BarChartOutlined
                            style={{
                              fontSize: "24px",
                              color: chartType?.histogram
                                ? "#f16521"
                                : "#ababab",
                              cursor:
                                !chartType?.timeSeries && !chartType?.boxPlot
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            onClick={() =>
                              !chartType?.timeSeries && !chartType?.boxPlot
                                ? null
                                : updateChartType(
                                    "histogram",
                                    !chartType.histogram
                                  )
                            }
                          />
                        </Tooltip>
                      </Col>
                      <Col>
                        <Tooltip title="Box Plot Chart">
                          <BoxPlotOutlined
                            style={{
                              fontSize: "24px",
                              color: chartType?.boxPlot ? "#f16521" : "#ababab",
                              cursor:
                                !chartType?.timeSeries && !chartType?.histogram
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            onClick={() =>
                              !chartType?.timeSeries && !chartType?.histogram
                                ? null
                                : updateChartType("boxPlot", !chartType.boxPlot)
                            }
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
            >
              {chartList.map((chart, index) => (
              <Row key={index} gutter={[16, 16]} className="mt05">
                {chartType?.timeSeries ? (
                  <Col xs={24} sm={24} md={24} flex={3}>
                    {vehicleDetails?.imeiNumber && (
                      <Iframe
                        title={"Time Series - " + chart}
                        src={`${baseURL}?orgId=${
                          userDetails?.currentOrganisation?.id
                        }&var-imei=${
                          vehicleDetails?.imeiNumber
                        }&var-interval=${getGrafanaInterval(
                          startTime,
                          stopTime
                        )}${chartQuery}&from=${startTime}&to=${stopTime}&panelId=${
                          TEST_RIDE_SUMMARY.PANEL_ID[chart][
                            "timeSeries"
                          ]
                        }`}
                        width="100%"
                        height="300"
                      />
                    )}
                  </Col>
                ) : null}
                {chartType?.histogram ? (
                  <Col xs={24} sm={24} md={24} flex={"auto"}>
                    {vehicleDetails?.imeiNumber && (
                      <Iframe
                        title={"Histogram " + chart}
                        src={`${baseURL}?orgId=${
                          userDetails?.currentOrganisation?.id
                        }&var-imei=${
                          vehicleDetails?.imeiNumber
                        }&var-interval=${getGrafanaInterval(
                          startTime,
                          stopTime
                        )}&${chartQuery}&from=${startTime}&to=${stopTime}&panelId=${
                          TEST_RIDE_SUMMARY.PANEL_ID[chart]
                            .histogram
                        }`}
                        width="100%"
                        height="300"
                      />
                    )}
                  </Col>
                ) : null}
                {chartType?.boxPlot ? (
                  <Col xs={24} sm={24} md={24} flex={"auto"}>
                    {vehicleDetails?.imeiNumber && (
                      <Iframe
                        title={"Boxplot " + chart}
                        src={`${baseURL}?orgId=${
                          userDetails?.currentOrganisation?.id
                        }&var-imei=${
                          vehicleDetails?.imeiNumber
                        }&var-interval=${getGrafanaInterval(
                          startTime,
                          stopTime
                        )}&${chartQuery}&from=${startTime}&to=${stopTime}&panelId=${
                          TEST_RIDE_SUMMARY.PANEL_ID[chart]
                            .boxPlot
                        }`}
                        width="100%"
                        height="300"
                      />
                    )}
                  </Col>
                ) : null}
              </Row>
              ))}
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default FullGraphsModal;
