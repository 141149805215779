import { Col, Layout, Row } from "antd";
import { useEffect, useState } from "react";
import CLOUD from "../../../assets/images/dealer/cloud.svg";
import TREES from "../../../assets/images/dealer/trees.svg";
import { Link } from "react-router-dom";
import {
  setDealerLayout,
  setDealerSlideShow,
} from "../../../reducers/authSlice";
import { useDispatch } from "react-redux";
import { useOrganization } from "../../../OrganizationProvider";
import { Content } from "antd/es/layout/layout";
import { dealerTheme } from "../../Common/Utils";
import Overview from "./Overview";

const Display = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const dispatch = useDispatch();
  const organization = useOrganization();

  useEffect(() => {
    dispatch(setDealerLayout(false));
    dispatch(setDealerSlideShow(true));

    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  const hours = currentTime.getHours().toString().padStart(2, "0");
  const minutes = currentTime.getMinutes().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;

  const formattedDate = {
    weekday: currentTime.toLocaleDateString("en-US", { weekday: "long" }),
    month: currentTime.toLocaleDateString("en-US", { month: "long" }),
    day: currentTime.toLocaleDateString("en-US", { day: "numeric" }),
  };

  return (
    <Layout
      className="dealer-layout"
      style={{ backgroundColor: dealerTheme[organization?.slug] }}
    >
      <Content>
        <Row>
          <Col span={24}>
            <Row gutter={[16, 16]} justify="space-between">
              <Col
                xs={24}
                sm={24}
                md={4}
                lg={4}
                xl={3}
                className="align-center"
              >
                <Link to="/">
                  <img
                    src={`${organization.s3BucketBaseUrl}/InnerLogo.svg`}
                    alt="Logo"
                  />
                </Link>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={13}
                lg={8}
                xl={8}
                className="flex-center"
              >
                <div className="dealer-name text-center">
                  Nandi Motor Dealers
                </div>
              </Col>
              <Col xs={24} sm={24} md={7} lg={6} xl={4}>
                <Row className="date-widget">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="flex-center"
                  >
                    <div className="time-widget flex-center">
                      {formattedTime}
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="text-center"
                  >
                    <div className="weekday">{formattedDate.weekday}</div>
                    <div className="day">{formattedDate.day}</div>
                    <div className="month">{formattedDate.month}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="mt1025">
              <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                <Overview />
                <Row gutter={[16, 16]} className="mt1">
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div className="dealer-card">
                      <div className="dealer-card-head">TOP SPEED</div>
                      <div className="dealer-card-body text-center">
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head24">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head24">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head22">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head22">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head20">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head20">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head18">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head18">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div className="dealer-card">
                      <div className="dealer-card-head">TOP SPEED</div>
                      <div className="dealer-card-body text-center">
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head24">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head24">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head22">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head22">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head20">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head20">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head18">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head18">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div className="dealer-card">
                      <div className="dealer-card-head">TOP SPEED</div>
                      <div className="dealer-card-body text-center">
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head24">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head24">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head22">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head22">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head20">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head20">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 16]}
                          justify="space-around"
                          className="flex-center mt05"
                        >
                          <Col span={12} className="head18">
                            KA09MH1209
                          </Col>
                          <Col span={12}>
                            <span className="head18">100 </span>
                            <span className="f12">km/h</span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                <div className="dealer-card">
                  <div className="dealer-card-head">EV BENEFITS</div>
                  <div className="dealer-card-body text-center f13">
                    <div>
                      <div>Annual fuel cost</div>
                      <div className="margin-center badge mt05">77</div>
                    </div>
                    <div className="mt105">
                      <div>Annual maintenance</div>
                      <div className="margin-center badge mt05">66</div>
                    </div>
                    <div className="mt1">
                      Total{" "}
                      <>
                        <span className="bold f14">5 </span>
                        <span className="f12">kg</span>
                      </>{" "}
                      CO2 saved
                    </div>
                    <div className="mt05 flex-center">
                      <img src={CLOUD} alt="Cloud" />
                    </div>
                    <div className="mt105">
                      Total{" "}
                      <>
                        <span className="bold f14">5 </span>
                      </>{" "}
                      trees saved
                    </div>
                    <div className="mt05 flex-center">
                      <img src={TREES} alt="Cloud" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="live-panel mt1">
                  Lorem Ipsum is a long established fact that a reader will be
                  distracted by the readable content of a page when looking at
                  its layout.
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Display;
