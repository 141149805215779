import { createSlice } from "@reduxjs/toolkit";

const fieldOptions = [
  {
    label: "Analog Temperature",
    value: "ai_temp",
  },
  {
    label: "Analog Voltage",
    value: "ai_vin",
  },
  {
    label: "Location Speed",
    value: "location_speed",
  },
  {
    label: "Motor Current",
    value: "dc_current",
  },
  {
    label: "Motor Voltage",
    value: "dc_voltage",
  },
  {
    label: "MCS Temperature",
    value: "mcs_temp",
  },
  {
    label: "Motor Temperature",
    value: "motor_temp",
  },
  {
    label: "Motor Speed",
    value: "motor_speed",
  },
  {
    label: "Throttle",
    value: "throttle",
  },
];

const fleetOptions = ["LIVE-FLEET", "ZOMATO"];
const vehicleOptions = {
  "LIVE-FLEET": ["861100065560043", "861100065560266", "All"],
  ZOMATO: ["861100065608966", "All"],
};

const initialState = {
  days: 1,
  from: {},
  to: {},
  motorField: fieldOptions[0].value,
  fleet: fleetOptions[0],
  imei: vehicleOptions[fleetOptions[0]][0],
  startTime: null,
  endTime: null,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    reset: () => initialState,
    setDays: (state, action) => {
      return {
        ...state,
        days: action.payload,
      };
    },
    setFrom: (state, action) => {
      return {
        ...state,
        from: action.payload,
      };
    },
    setTo: (state, action) => {
      return {
        ...state,
        to: action.payload,
      };
    },
    setMotorField: (state, action) => {
      return {
        ...state,
        motorField: action.payload,
      };
    },
    setFleet: (state, action) => {
      return {
        ...state,
        fleet: action.payload,
      };
    },
    setImei: (state, action) => {
      return {
        ...state,
        imei: action.payload,
      };
    },
    setStartTime: (state, action) => {
      return {
        ...state,
        startTime: action.payload,
      };
    },
    setEndTime: (state, action) => {
      return {
        ...state,
        endTime: action.payload,
      };
    },
  },
});

export const selectFieldOptions = (state) => fieldOptions;
export const selectFleetOptions = (state) => fleetOptions;
export const selectVehicleOptions = (state) => vehicleOptions;

export const {
  setDays,
  reset,
  setFrom,
  setTo,
  setMotorField,
  setFleet,
  setImei,
  setStartTime,
  setEndTime,
} = filterSlice.actions;

export default filterSlice.reducer;
