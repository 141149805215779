import { Col, Row, Select, Tooltip } from "antd";
import Iframe from "../Common/Iframe";
import {
  BarChartOutlined,
  BoxPlotOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { batteryFields } from "./DropDownData";
import { grafanaBaseUrl } from "../Common/endpoints";

const BatteryPanels = ({ imei }) => {
  const [batteryField, setBatteryField] = useState(batteryFields[0]);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const sensorTimeSeries = `${grafanaBaseUrl}/d-solo/cf7a11f5-3451-4ced-91c5-4659b72e163d/executive-landing-page-details?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${imei}&var-batteryfield=${batteryField}&var-bucket_interval=1h&from=1697567400000&to=1697653799000&panelId=7`;
  const sensorHistogram = `${grafanaBaseUrl}/d-solo/cf7a11f5-3451-4ced-91c5-4659b72e163d/executive-landing-page-details?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${imei}&var-batteryfield=${batteryField}&var-bucket_interval=1h&from=1697567400000&to=1697653799000&panelId=8`;
  const sensorBoxPlot = `${grafanaBaseUrl}/d-solo/cf7a11f5-3451-4ced-91c5-4659b72e163d/executive-landing-page-details?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${imei}&var-batteryfield=${batteryField}&var-bucket_interval=1h&from=1697567400000&to=1697653799000&panelId=9`;
  const [chartType, setChartType] = useState({
    timeSeries: true,
    histogram: false,
    boxPlot: false,
  });

  const updateChartType = (name, value) => {
    setChartType((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeField = (value) => {
    setBatteryField(value);
  };

  return (
    <>
      <Row gutter={[16, 16]} justify="space-between" className="mtb1">
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
          <Select
            value={batteryField}
            style={{ width: "100%" }}
            onChange={onChangeField}
            options={batteryFields.map((i) => ({
              label: i.label,
              value: i.value,
            }))}
          />
        </Col>
        <Col span={3}>
          <Row gutter={[16, 16]} justify="end">
            <Col span={8}>
              <Tooltip title="Time Series Chart">
                <LineChartOutlined
                  style={{
                    fontSize: "24px",
                    color: chartType?.timeSeries ? "#f16521" : "#ababab",
                    cursor:
                      !chartType?.histogram && !chartType?.boxPlot
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={() =>
                    !chartType?.histogram && !chartType?.boxPlot
                      ? null
                      : updateChartType("timeSeries", !chartType.timeSeries)
                  }
                />
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title="Histogram Chart">
                <BarChartOutlined
                  style={{
                    fontSize: "24px",
                    color: chartType?.histogram ? "#f16521" : "#ababab",
                    cursor:
                      !chartType?.timeSeries && !chartType?.boxPlot
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={() =>
                    !chartType?.timeSeries && !chartType?.boxPlot
                      ? null
                      : updateChartType("histogram", !chartType.histogram)
                  }
                />
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title="Box Plot Chart">
                <BoxPlotOutlined
                  style={{
                    fontSize: "24px",
                    color: chartType?.boxPlot ? "#f16521" : "#ababab",
                    cursor:
                      !chartType?.timeSeries && !chartType?.histogram
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={() =>
                    !chartType?.timeSeries && !chartType?.histogram
                      ? null
                      : updateChartType("boxPlot", !chartType.boxPlot)
                  }
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt1">
        {chartType?.timeSeries && (
          <Col xs={24} sm={24} md={24} flex={"auto"}>
            <Iframe
              title="Sensor TimeSeries"
              src={sensorTimeSeries}
              width="100%"
              height="220"
            />
          </Col>
        )}
        {chartType?.histogram && (
          <Col xs={24} sm={24} md={24} flex={"auto"}>
            <Iframe
              title="Sensor TimeSeries"
              src={sensorHistogram}
              width="100%"
              height="220"
            />
          </Col>
        )}
        {chartType?.boxPlot && (
          <Col xs={24} sm={24} md={24} flex={"auto"}>
            <Iframe
              title="Sensor TimeSeries"
              src={sensorBoxPlot}
              width="100%"
              height="220"
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default BatteryPanels;
