import { Col, Divider, Empty, Modal, Row } from "antd";
import VEHICLE from "../../assets/images/dealer/model.svg";
import DISTANCE from "../../assets/images/icons/distance.svg";
import TOP_RUNNING from "../../assets/images/top.svg";
import RED_BADGE from "../../assets/images/redBadge.svg";
import GREEN_BADGE from "../../assets/images/greenBadge.svg";
import LEAST_RUNNING from "../../assets/images/least.svg";
import HEARTBEAT from "../../assets/images/icons/heartbeat.svg";
import RIDES from "../../assets/images/icons/ride.svg";
import DYNO from "../../assets/images/icons/top-speed.svg";
import { useState } from "react";
import { UNITS } from "../Common/Constants";

const Overview = ({ metaData }) => {
  const [topRunningModalOpen, setTopRunningModalOpen] = useState(false);
  const [leastRunningModalOpen, setLeastRunningModalOpen] = useState(false);

  const showTopRunningModal = () => {
    setTopRunningModalOpen(true);
  };

  const showLeastRunningModal = () => {
    setLeastRunningModalOpen(true);
  };

  return (
    <>
      <Modal
        title="Top 10 Running Vehicles"
        open={topRunningModalOpen}
        onCancel={() => setTopRunningModalOpen(false)}
        footer={false}
        width={600}
        style={{ top: "20px" }}
      >
        <Row className="flex-center">
          <Col span={22} className="mt1">
            <ul className="ul">
              {Object.keys(metaData).length === 0 ? (
                <Empty />
              ) : (
                metaData?.topRunningVehicles?.map((item, index) => {
                  return (
                    <li key={index} className="list">
                      <Row gutter={[16, 16]} className="flex-center">
                        <Col span={16} className="f13 grey bold">
                          {item.imei}
                        </Col>
                        <Col span={6} className="justify-right">
                          <span className="green head mr025">
                            {item.distance}
                          </span>
                          <span className="mt01 grey">{UNITS.KM}</span>
                        </Col>
                      </Row>
                    </li>
                  );
                })
              )}
            </ul>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Last 10 Running Vehicles"
        open={leastRunningModalOpen}
        onCancel={() => setLeastRunningModalOpen(false)}
        footer={false}
        width={600}
        style={{ top: "20px" }}
      >
        <Row className="flex-center">
          <Col span={22} className="mt1">
            <ul className="ul">
              {Object.keys(metaData).length === 0 ? (
                <Empty />
              ) : (
                metaData?.leastRunningVehicles?.map((item, index) => {
                  return (
                    <li key={index} className="list">
                      <Row gutter={[16, 16]} className="flex-center">
                        <Col span={16} className="f13 grey bold">
                          {item.imei}
                        </Col>
                        <Col span={6} className="justify-right">
                          <span className="orange head mr025">
                            {item.distance}
                          </span>
                          <span className="mt01 grey">{UNITS.KM}</span>
                        </Col>
                      </Row>
                    </li>
                  );
                })
              )}
            </ul>
          </Col>
        </Row>
      </Modal>

      <Row gutter={[12, 16]}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Row className="shadow-card">
            <Col span={12} className="mht150 flex-center">
              <div className="head">
                <img src={VEHICLE} alt="Scooter" width="120" />
              </div>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24} className="flex-center">
                  <div className="normal-head mt2">Total Vehicles</div>
                </Col>
                <Col span={24} className="text-center">
                  <div className="head30 mt2">{metaData?.totalVehicles}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Row className="shadow-card">
            <Col span={12} className="mht150 flex-center">
              <div className="head">
                <img src={DISTANCE} alt="Scooter" width="120" />
              </div>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24} className="flex-center">
                  <div className="normal-head mt2">Total Distance</div>
                </Col>
                <Col span={24} className="flex-center">
                  <div className="head30 mt2">{metaData?.totalDistance}</div>
                  <div className="head mt2025 ml025">km</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <div className="shadow-card">
            <Row className="flex-center" justify="space-between">
              <Col span={18} className="align-center">
                <img src={HEARTBEAT} alt="km" className="icon24 mr05" />
                Total Heartbeat Tests
              </Col>
              <Col span={6}>
                <div className="head26 flex-end">
                  {metaData?.totalHeartBeatTest}
                </div>
              </Col>
            </Row>
            <Row className="flex-center mt1" justify="space-between">
              <Col span={18} className="align-center">
                <img src={DYNO} alt="km" className="icon24 mr05" />
                Total Dyno Tests
              </Col>
              <Col span={6}>
                <div className="head26 flex-end">{metaData?.totalDynoTest}</div>
              </Col>
            </Row>
            <Row className="flex-center mt1" justify="space-between">
              <Col span={18} className="align-center">
                <img src={RIDES} alt="km" className="icon24 mr05" />
                Total Test Rides
              </Col>
              <Col span={6}>
                <div className="head26 flex-end">
                  {metaData?.totalTestRides}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <div className="shadow-card mt1">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={11} xl={11}>
            <Row>
              <Col span={24}>
                <div className="normal-head flex-center">
                  <img src={GREEN_BADGE} alt="Top" className="mr05" /> Top
                  Running
                </div>
              </Col>
            </Row>

            <Divider className="hr-margin" />
            <Row>
              <Col span={12} className="flex-center">
                <img src={TOP_RUNNING} alt="Scooter" width="60" />
              </Col>
              <Col span={12} className="text-center">
                <div className="flex-center mt05">
                  <div className="head30">{metaData?.maxRunning}</div>
                  <div className="mt05 ml025">km</div>
                </div>
                {Object.keys(metaData).length === 0 ? (
                  <div className="link-disabled" title="No data">
                    Top 10 Vehicles
                  </div>
                ) : (
                  <div className="link" onClick={() => showTopRunningModal()}>
                    Top 10 Vehicles
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="flex-center">
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={11} xl={11}>
            <Row>
              <Col span={24}>
                <div className="normal-head flex-center">
                  <img src={RED_BADGE} alt="Least" className="mr05" /> Least
                  Running
                </div>
              </Col>
            </Row>

            <Divider className="hr-margin" />
            <Row>
              <Col span={12} className="flex-center">
                <img src={LEAST_RUNNING} alt="Scooter" width="60" />
              </Col>
              <Col span={12} className="text-center">
                <div className="flex-center mt05">
                  <div className="head30">{metaData?.minRunning}</div>
                  <div className="mt05 ml025">km</div>
                </div>
                {Object.keys(metaData).length === 0 ? (
                  <div className="link-disabled" title="No data">
                    Last 10 Vehicles
                  </div>
                ) : (
                  <div className="link" onClick={() => showLeastRunningModal()}>
                    Last 10 Vehicles
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Overview;
