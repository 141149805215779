import { ROUTES, ROLES, ORG_TYPES, DATE_TIME_PICKER } from "./Constants";
import dayjs from "dayjs";

export const setUserDetails = (data) => {
  localStorage.setItem("authToken", data?.authToken);
  const userDetails = {
    email: data?.emailAddress,
    firstName: data?.firstName,
    lastName: data?.lastName,
    phoneNumber: data?.phoneNumber,
    organisations: data?.organisations,
    roles: data?.roles,
    currentRole: data?.roles[0],
    currentOrganisation: data?.organisations[0],
  };
  localStorage.setItem("userDetails", JSON.stringify(userDetails));
};

export const numberRegEx = /^[0-9]+$/;

export const calculateDuration = (record) => {
  const timeDifference = record.stopTime - record.startTime;
  const seconds = timeDifference / 1000;
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (seconds >= 60) {
    const remainingSeconds = Math.floor(seconds % 60);
    if (minutes >= 60) {
      const remainingMinutes = Math.floor(minutes % 60);
      return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`;
    } else {
      return `${minutes}m ${remainingSeconds}s`;
    }
  } else {
    return `${seconds.toFixed(0)}s`;
  }
};

export const formatDateAndTime = (timestamp) => {
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(timestamp)
    .toLocaleDateString("en-US", options)
    .replace(",", "");
};

const getItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

const defaultDashboards = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  let items = [];
  if (userDetails?.currentOrganisation?.type === ORG_TYPES.DEALER) {
    items.push(
      getItem(ROUTES.DEALER_SLIDESHOW.title, ROUTES.DEALER_SLIDESHOW.key, "")
    );
    items.push(
      getItem(ROUTES.DEALER_SERVICE.title, ROUTES.DEALER_SERVICE.key, "")
    );
  } else {
    items.push(getItem(ROUTES.FLEET_STATUS.title, ROUTES.FLEET_STATUS.key, ""));
    if (userDetails?.currentOrganisation?.type !== ORG_TYPES.B2B_CUSTOMER) {
      items.push({ type: "divider" });
      items.push(getItem(ROUTES.EOL_TESTS.title, ROUTES.EOL_TESTS.key, ""));
      items.push(
        getItem(ROUTES.EOL_STATISTICS.title, ROUTES.EOL_STATISTICS.key, "")
      );
      items.push({ type: "divider" });
      items.push(getItem(ROUTES.TEST_RIDES.title, ROUTES.TEST_RIDES.key, ""));
      items.push(
        getItem(
          ROUTES.TEST_RIDE_STATISTICS.title,
          ROUTES.TEST_RIDE_STATISTICS.key,
          ""
        )
      );
    }
  }

  return items;
};

export const dashboardsList = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const dashboards = {
    [ROLES.ADMIN]: defaultDashboards(),
    [ROLES.EXECUTIVE]: defaultDashboards(),
    [ROLES.TEST_ENGINEER]: [
      getItem(ROUTES.EOL_TESTS.title, ROUTES.EOL_TESTS.key, ""),
      getItem(ROUTES.EOL_STATISTICS.title, ROUTES.EOL_STATISTICS.key, ""),
    ],
    [ROLES.TEST_RIDE_ENGINEER]: [
      getItem(ROUTES.TEST_RIDES.title, ROUTES.TEST_RIDES.key, ""),
      getItem(
        ROUTES.TEST_RIDE_STATISTICS.title,
        ROUTES.TEST_RIDE_STATISTICS.key,
        ""
      ),
    ],
    [ROLES.FLEET_MANAGER]: [
      getItem(ROUTES.FLEET_STATUS.title, ROUTES.FLEET_STATUS.key, ""),
      getItem(ROUTES.TEST_RIDES.title, ROUTES.TEST_RIDES.key, ""),
      getItem(
        ROUTES.TEST_RIDE_STATISTICS.title,
        ROUTES.TEST_RIDE_STATISTICS.key,
        ""
      ),
    ],
  };
  return dashboards[userDetails?.currentRole];
};

export const rolesToExclude = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const type = userDetails?.currentOrganisation?.type;
  if (type && type === ORG_TYPES.MANUFACTURER) {
    return [ROLES.RIDER, ROLES.ADMIN];
  } else if (type && type === ORG_TYPES.B2B_CUSTOMER) {
    return [ROLES.ADMIN, ROLES.TEST_ENGINEER, ROLES.TEST_RIDE_ENGINEER];
  } else {
    return [];
  }
};

export const redirectToRelatedPage = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  if (userDetails?.currentOrganisation?.type === ORG_TYPES.ADMINISTRATOR) {
    switch (userDetails?.currentRole) {
      case ROLES.TEST_ENGINEER:
        return ROUTES.EOL_TESTS.link;
      case ROLES.TEST_RIDE_ENGINEER:
        return ROUTES.TEST_RIDES.link;
      case ROLES.FLEET_MANAGER:
        return ROUTES.FLEET_STATUS.link;
      default:
        return ROUTES.FLEET_STATUS.link;
    }
  } else if (userDetails?.currentOrganisation?.type === ORG_TYPES.DEALER) {
    return ROUTES.DEALER_EXECUTIVE.link;
  } else {
    switch (userDetails?.currentRole) {
      case ROLES.TEST_ENGINEER:
        return ROUTES.EOL_TESTS.link;
      case ROLES.TEST_RIDE_ENGINEER:
        return ROUTES.TEST_RIDES.link;
      case ROLES.FLEET_MANAGER:
        return ROUTES.FLEET_STATUS.link;
      default:
        return ROUTES.HOME.link;
    }
  }
};

export const userPages = (userDetails) => {
  const items = [getItem(ROUTES.PROFILE.title, ROUTES.PROFILE.key, "")];
  if (userDetails?.currentRole === ROLES.ADMIN) {
    items.push(getItem(ROUTES.REGISTER.title, ROUTES.REGISTER.key, ""));
  }
  items.push({ type: "divider" });
  items.push(getItem(ROUTES.LOGOUT.title, ROUTES.LOGOUT.key, ""));
  return items;
};

export const footerText = `EV Analytics © ${new Date().getFullYear()} Created by NicheSolv`;

export const getGrafanaInterval = (startTime, endTime) => {
  const timeDifference = Math.abs(endTime - startTime);
  const timeDifferenceMinutes = Math.floor(timeDifference / (1000 * 60));
  if (timeDifferenceMinutes > 60 * 24 * 7 * 3) {
    return "2h";
  } else if (
    timeDifferenceMinutes > 60 * 24 * 6 &&
    timeDifferenceMinutes <= 60 * 24 * 7 * 3
  ) {
    return "1h";
  } else if (
    timeDifferenceMinutes > 60 * 24 * 2 &&
    timeDifferenceMinutes <= 60 * 24 * 6
  ) {
    return "15m";
  } else if (
    timeDifferenceMinutes > 60 * 24 &&
    timeDifferenceMinutes <= 60 * 24 * 2
  ) {
    return "5m";
  } else if (timeDifferenceMinutes <= 60 * 24) {
    return "1s";
  }

  return "";
};

export const timeRangePresets = [
  {
    label: DATE_TIME_PICKER.FILTER_LABLE.FIVE_MINS,
    value: [dayjs().subtract(5, "m"), dayjs()],
  },
  {
    label: DATE_TIME_PICKER.FILTER_LABLE.FIFTEEN_MINS,
    value: [dayjs().subtract(15, "m"), dayjs()],
  },
  {
    label: DATE_TIME_PICKER.FILTER_LABLE.THIRTY_MINS,
    value: [dayjs().subtract(30, "m"), dayjs()],
  },
  {
    label: DATE_TIME_PICKER.FILTER_LABLE.TODAY,
    value: [
      dayjs().set("hour", 0).set("minute", 0).set("second", 0),
      dayjs().endOf("day"),
    ],
  },
  {
    label: DATE_TIME_PICKER.FILTER_LABLE.YESTERDAY,
    value: [
      dayjs().subtract(1, "d").set("hour", 0).set("minute", 0).set("second", 0),
      dayjs().subtract(1, "d").endOf("day"),
    ],
  },
  {
    label: DATE_TIME_PICKER.FILTER_LABLE.THIS_WEEK,
    value: [
      dayjs()
        .subtract(dayjs().get("day"), "d")
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0),
      dayjs().endOf("day"),
    ],
  },
  {
    label: DATE_TIME_PICKER.FILTER_LABLE.LAST_WEEK,
    value: [
      dayjs()
        .subtract(dayjs().get("day") + 7, "d")
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0),
      dayjs()
        .subtract(dayjs().get("day") + 1, "d")
        .endOf("day"),
    ],
  },
  {
    label: DATE_TIME_PICKER.FILTER_LABLE.THIS_MONTH,
    value: [
      dayjs()
        .subtract(dayjs().get("date") - 1, "d")
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0),
      dayjs().endOf("day"),
    ],
  },
  {
    label: DATE_TIME_PICKER.FILTER_LABLE.LAST_MONTH,
    value: [
      dayjs()
        .subtract(1, "M")
        .set("date", 1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0),
      dayjs().subtract(dayjs().get("date"), "d").endOf("day"),
    ],
  },
];

export const getScreenType = () => {
  if (window.innerWidth >= 1600) {
    return "xxl";
  } else if (window.innerWidth >= 1200) {
    return "xl";
  } else if (window.innerWidth >= 992) {
    return "lg";
  } else if (window.innerWidth >= 768) {
    return "md";
  } else if (window.innerWidth >= 576) {
    return "sm";
  } else if (window.innerWidth >= 480) {
    return "xs";
  } else {
    return "xxs";
  }
};

export const dealerTheme = {
  lml: "#F3A6AC",
  nds: "#a67657",
  evdealer: "#a67657",
  nichesolv: "#ACB546",
};

export const formattedStringForDropdown = (str) => {
  return str
    ? str
        .toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";
};

export const isWithinLast7Days = (date) => {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  return date <= sevenDaysAgo;
};
