import "./style.css";
import { Button, Col, Row, Select, Switch, Divider, Rate } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { getApis } from "../../Common/apis";
import { imeiListApiUrl } from "../../Common/endpoints";
import VEHICLE from "../../../assets/images/dealer/model.svg";
import {
  setDealerLayout,
  setDealerSlideShow,
} from "../../../reducers/authSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Ratings from "./Ratings";

const Service = () => {
  const [imei, setImei] = useState("");
  const [imeiList, setImeiList] = useState([]);
  const authToken = localStorage.getItem("authToken");
  const dispatch = useDispatch();

  const getImeiList = useCallback(async () => {
    const response = await getApis(
      `${imeiListApiUrl}?page=0&size=15&sort=asc`,
      {},
      authToken
    );
    setImeiList(response?.data);
    setImei(response?.data[0]);
  }, [authToken]);

  useEffect(() => {
    getImeiList();
    dispatch(setDealerLayout(true));
    dispatch(setDealerSlideShow(false));
  }, [dispatch, getImeiList]);

  const onChangeImei = (value) => {
    setImei(value);
  };

  const serviceHistories = [
    {
      slno: 1,
      imei: "861100065560043",
      date: "12-05-2023",
    },
    {
      slno: 2,
      imei: "861100065560043",
      date: "12-05-2023",
    },
    {
      slno: 3,
      imei: "861100065560043",
      date: "12-05-2023",
    },
    {
      slno: 4,
      imei: "861100065560043",
      date: "12-05-2023",
    },
    {
      slno: 5,
      imei: "861100065560043",
      date: "12-05-2023",
    },
    {
      slno: 6,
      imei: "861100065560043",
      date: "12-05-2023",
    },
  ];

  return (
    <Row gutter={[16, 16]} className="dealer-service">
      <Col xs={24} sm={24} md={24} lg={7} xl={7}>
        <Row gutter={[16, 16]} className="h100">
          <Col xs={24} sm={24} md={12} lg={24} xl={24} className="serivce-status">
            <div className="dealer-card">
              <div className="dealer-card-head">SERVICE STATUS - TODAY</div>
              <div className="dealer-card-body p0 box-hgt">
                <Row className="h100">
                  <Col span={12} className="dealer-done-card mb05">
                    <Row className="dealer-card-second-head mb05">Done</Row>
                    {[
                      {
                        mode: "SQD",
                        count: 3,
                      },
                      {
                        mode: "SQD ULTRA",
                        count: 3,
                      },
                      {
                        mode: "SQD STAR",
                        count: 6,
                      },
                      {
                        mode: "SQD ULTRA",
                        count: 6,
                      },
                      {
                        mode: "SQD ULTRA",
                        count: 6,
                      },
                    ].map((item, index) => {
                      return (
                        <Row key={index}>
                          <Col span={24} className="float-left">
                            <div className="pd075 ml1">
                              {item.mode} - {item.count}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                  <Col span={12} className="mb05">
                    <Row className="dealer-card-second-head mb05">
                      In Progress
                    </Row>
                    {[
                      {
                        mode: "SQD",
                        count: 3,
                      },
                      {
                        mode: "SQD ULTRA",
                        count: 3,
                      },
                      {
                        mode: "SQD STAR",
                        count: 6,
                      },
                      {
                        mode: "SQD ULTRA",
                        count: 6,
                      },
                      {
                        mode: "SQD ULTRA",
                        count: 6,
                      },
                    ].map((item, index) => {
                      return (
                        <Row key={index}>
                          <Col span={24} className="float-left">
                            <div className="pd075 ml1">
                              {item.mode} - {item.count}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={24} xl={24}>
            <div className="dealer-card">
              <div className="dealer-card-head">SERVICE BOOKING - TOMORROW</div>
              <div className="dealer-card-body p0 box-hgt">
                <Row>
                  <Col span={24} className="mb05">
                    <div className="dealer-card-second-head mb05">Count</div>
                    {[
                      {
                        mode: "SQD",
                        count: 3,
                      },
                      {
                        mode: "SQD ULTRA",
                        count: 3,
                      },
                      {
                        mode: "SQD STAR",
                        count: 6,
                      },
                      {
                        mode: "SQD ULTRA",
                        count: 6,
                      },
                      {
                        mode: "SQD ULTRA",
                        count: 6,
                      },
                    ].map((item, index) => {
                      return (
                        <Row key={index}>
                          <Col span={24} className="float-left">
                            <div className="pd075 ml1">
                              {item.mode} - {item.count}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={17} xl={17}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="vehicle-health">
            <div className="dealer-card">
              <div className="dealer-card-head">VEHICLE HEALTH</div>
              <div className="dealer-card-body f13">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={8} md={6}>
                    <Row>
                      <Col span={24}>
                        {imeiList && imeiList.length > 0 && (
                          <Select
                            showSearch
                            value={imei || imeiList[0]}
                            style={{ width: "100%", minWidth: "200px" }}
                            onChange={onChangeImei}
                            options={imeiList.map((i) => ({
                              label: i,
                              value: i,
                            }))}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="flex-center mt1">
                        <img src={VEHICLE} alt="Scooter" width="120" />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div className="mt1 text-center">
                          <div className="mt025">Overall</div>
                          <div className="mt1">
                            <Rate allowHalf defaultValue={2.5} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={16} md={18}>
                    <Row gutter={[16, 16]} justify={"space-evenly"}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                        <div className="shadow-card h100 flex-center">
                          <div>
                            <div className="vehicle-card-title">
                              Vehicle Number
                            </div>
                            <div className="vehicle-card-value mt1">
                              KA09 MH1234
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                        <div className="shadow-card h100 flex-center">
                          <div>
                            <div className="vehicle-card-title">
                              Last Service At
                            </div>
                            <div className="vehicle-card-value mt1">
                              14 Jun 23
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                        <div className="shadow-card h100 flex-center">
                          <div>
                            <div className="vehicle-card-title">Odometer</div>
                            <div className="head mt1">90873</div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                        <div className="shadow-card h100 flex-center">
                          <div>
                            <div className="vehicle-card-title">Sale Date</div>
                            <div className="vehicle-card-value mt1">
                              12-06-2023
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Ratings />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt1">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div class="modal-widget">
              <div class="modal-widget-header">TOP ISSUES FOUND</div>
              <div class="modal-widget-body box-hgt">
                {[
                  {
                    slno: 1,
                    title: "Motor current is high",
                    isTrue: false,
                  },
                  {
                    slno: 2,
                    title: "Brakes health is fine",
                    isTrue: false,
                  },
                  {
                    slno: 3,
                    title: "Motor Temperature is high",
                    isTrue: false,
                  },
                  {
                    slno: 4,
                    title: "DC Voltage is high",
                    isTrue: false,
                  },
                  {
                    slno: 5,
                    title: "DC Current is high",
                    isTrue: false,
                  },
                ].map((item, index) => {
                  return (
                    <Row
                      key={index}
                      gutter={[16, 16]}
                      justify="space-between"
                      className="pd075"
                    >
                      <Col span={2}>{item.slno}</Col>
                      <Col span={18}>{item.title}</Col>
                      <Col span={4}>
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Col>
                    </Row>
                  );
                })}
              </div>
              <div class="modal-widget-footer">
                <div class="top-divider"></div>
                <Button type="submit" size="small">
                  Submit
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="dealer-card">
              <div className="dealer-card-head">SERVICE HISTORY</div>
              <div className="dealer-card-body scrollable box-hgt">
                {serviceHistories.map((item, index) => {
                  return (
                    <Link key={index} to="/" className="link-row">
                      <Row
                        gutter={[16, 16]}
                        justify="space-between"
                        className="pd075"
                      >
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                          {item.slno}
                        </Col>
                        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                          {item.imei}
                        </Col>
                        <Col
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          className="flex-end"
                        >
                          {item.date}
                        </Col>
                      </Row>
                      {serviceHistories.length - 1 !== index && (
                        <Row>
                          <Col span={24}>
                            <Divider className="hr-no-margin" />
                          </Col>
                        </Row>
                      )}
                    </Link>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Service;
