import { Card, Col, Progress, Row } from "antd";
import { useSelector } from "react-redux";
import { HistoryOutlined } from "@ant-design/icons";
import { formatDateAndTime } from "../../../../../Common/Utils";
import VehicleDetails from "../../../../VehicleDetails";
import Iframe from "../../../../../Common/Iframe";
import { grafanaBaseUrl } from "../../../../../Common/endpoints";

const Result = ({ onChangeImei, imei }) => {
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const connectivityStop = eol?.connectivityStop;
  const testRunning = eol?.testRunning;
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const result_current_crc = `${grafanaBaseUrl}/d-solo/bbeb33a9-6b02-46cd-b9e2-be84fc52a2dg/eol-heart-beat-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${connectivityStop?.startTime}&to=${connectivityStop?.stopTime}&panelId=6`;
  const result_voltage_src = `${grafanaBaseUrl}/d-solo/bbeb33a9-6b02-46cd-b9e2-be84fc52a2dg/eol-heart-beat-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${connectivityStop?.startTime}&to=${connectivityStop?.stopTime}&panelId=5`;

  const running_current_src = `${grafanaBaseUrl}/d-solo/bbeb33a9-6b02-46cd-b9e2-be84fc52a2dg/eol-heart-beat-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&refresh=5s&panelId=6`;
  const running_voltage_src = `${grafanaBaseUrl}/d-solo/bbeb33a9-6b02-46cd-b9e2-be84fc52a2dg/eol-heart-beat-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&refresh=5s&panelId=5`;

  const list = () => {
    return [
      { TCU: connectivityStop?.data?.connection?.tcu },
      { Motor: connectivityStop?.data?.connection?.motor },
      { BMS: connectivityStop?.data?.connection?.battery },
      { GPS: connectivityStop?.data?.connection?.gps },
      { Current: connectivityStop?.data?.threshold?.current_checked },
      { Voltage: connectivityStop?.data?.threshold?.voltage_checked },
      { "Last 5m": connectivityStop?.data?.availability?.last5min },
      { Interval: connectivityStop?.data?.availability?.testDuration },
      { SOC: connectivityStop?.data?.availability?.soc },
      { "Data Lag": connectivityStop?.data?.availability?.lag },
    ];
  };

  const gridStyle = {
    width: "20%",
    textAlign: "center",
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <VehicleDetails onChangeImei={onChangeImei} imei={imei} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={19} xl={19}>
          <Card
            title="Heart Beat Result"
            extra={
              connectivityStop?.startTime && (
                <div className="fs13">
                  <HistoryOutlined />
                  <span className="card-sub-text ml05">
                    {formatDateAndTime(connectivityStop?.startTime)}
                  </span>
                </div>
              )
            }
            bodyStyle={{
              padding: "4px",
              minHeight: "243px",
            }}
          >
            {list().map((item, index) => {
              const label = Object.keys(item)[0];
              const value = item[label];
              return (
                <Card.Grid key={index} style={gridStyle}>
                  <div>{label}</div>
                  {testRunning ? (
                    <Progress
                      type="circle"
                      size={40}
                      percent={0}
                      strokeColor={{
                        "0%": "#108ee9",
                      }}
                      className="mt05"
                    />
                  ) : label === "Data Lag" ? (
                    <Progress
                      type="circle"
                      size={40}
                      percent={connectivityStop?.data?.availability?.lag}
                      format={(percent) => `${percent}s`}
                      strokeColor={{
                        "0%": "#108ee9",
                        "100%": "#87d068",
                      }}
                      className="mt05"
                    />
                  ) : value || value === 0 ? (
                    <Progress
                      type="circle"
                      size={40}
                      percent={value === true ? 100 : value}
                      strokeColor={{
                        "0%": "#108ee9",
                        "100%": "#87d068",
                      }}
                      className="mt05"
                    />
                  ) : (
                    <Progress
                      type="circle"
                      size={40}
                      percent={100}
                      status="exception"
                      className="mt05"
                    />
                  )}
                </Card.Grid>
              );
            })}
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 8]} className="mt1">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Current"
              src={testRunning ? running_current_src : result_current_crc}
              width="100%"
              height="220"
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Voltage"
              src={testRunning ? running_voltage_src : result_voltage_src}
              width="100%"
              height="220"
            />
          )}
        </Col>
      </Row>
    </>
  );
};
export default Result;
