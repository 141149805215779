import { Card, Col, Row } from "antd";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDynoHistoryData } from "../../../../../../reducers/eolSlice";
import { getApis } from "../../../../../Common/apis";
import {
  grafanaBaseUrl,
  vehicleTestListUrl,
} from "../../../../../Common/endpoints";
import DynoTests from "./DynoTests";
import ModeGrafana from "./ModeGrafana";
import Iframe from "../../../../../Common/Iframe";
import VehicleDetails from "../../../../VehicleDetails";

const History = ({ onChangeImei, imei }) => {
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const eol = useSelector((state) => state.eol);
  const vehicleDetails = eol?.vehicleDetails;
  const response = eol?.dynoResult;
  const authToken = localStorage.getItem("authToken");
  const current_src = `${grafanaBaseUrl}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&panelId=4`;
  const all_mode_src = `${grafanaBaseUrl}/d-solo/c4d4b681-6104-4877-aeee-b366cebc4065/eol-dyno-testing?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&from=${response.startTime}&to=${response.stopTime}&panelId=15`;

  const getDynoHistoryData = useCallback(async () => {
    await getApis(
      vehicleTestListUrl,
      {
        imei: vehicleDetails.imeiNumber,
        type: "DYNO",
      },
      authToken
    )
      .then((res) => dispatch(setDynoHistoryData(res?.data)))
      .catch((e) => console.log(e));
  }, [authToken, dispatch, vehicleDetails.imeiNumber]);

  useEffect(() => {
    if (vehicleDetails.imeiNumber) {
      getDynoHistoryData();
    }
  }, [getDynoHistoryData, vehicleDetails.imeiNumber]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <VehicleDetails onChangeImei={onChangeImei} imei={imei} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={19} xl={19}>
          <Card
            title="Dyno Tests History"
            bodyStyle={{
              padding: "4px",
              minHeight: "243px",
            }}
          >
            <DynoTests />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 8]} className="mt1">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Current"
              src={current_src}
              width="100%"
              height="220"
            />
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Speed"
              src={all_mode_src}
              width="100%"
              height="220"
            />
          )}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          {vehicleDetails?.driveModeSpeed?.map((item, index) => (
            <ModeGrafana item={item} key={index} />
          ))}
        </Col>
      </Row>
    </>
  );
};
export default History;
