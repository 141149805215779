import { Col, Row, Divider } from "antd";
import { useSelector } from "react-redux";
import { getGrafanaInterval } from "../../../../Common/Utils";
import BRAKE from "../../../../../assets/images/icons/brake.svg";
import AddToCompare from "../addToCompare";
import PanelRow from "../panelRow";
import { grafanaBaseUrl } from "../../../../Common/endpoints";
import CardCollapse from "../../../../Common/CardCollapse";
import { UNITS } from "../../../../Common/Constants";

const BrakeAnalytics = ({ compareValue, updateCompareValue }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  const brakeDurationTimeSeriesChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=8`;
  const brakeDurationHistogramChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=9`;
  const brakeDurationBoxPlotChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=19`;

  const brakeDistanceTimeSeriesChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=15`;
  const brakeDistanceHistogramChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=16`;
  const brakeDistanceBoxPlotChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=20`;

  const subContent = (
    <Row gutter={[16, 16]} className="mt05">
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        Total Brakes: {tripSummary?.totalBrakes}
      </Col>
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        Brakes/km: {tripSummary?.brakesPerKm}
      </Col>
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        Avg Distance/Brake: {tripSummary?.averageDistancePerBrake} {UNITS.METER}
      </Col>
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        Avg Duration/Brake: {tripSummary?.averageDurationPerBrake}{" "}
        {UNITS.SECONDS}
      </Col>
    </Row>
  );

  return (
    <CardCollapse
      cardClassName="ride-statistic-analytics-card"
      icon={BRAKE}
      title={"Brake Analytics"}
      subContent={subContent}
    >
      <Row className="mt1 pd0">
        <Col span={24}>
          {vehicleDetails?.imeiNumber && (
            <PanelRow
              title={"Brake Duration"}
              timeSeriesURL={brakeDurationTimeSeriesChart}
              histogramURL={brakeDurationHistogramChart}
              boxPlotURL={brakeDurationBoxPlotChart}
            />
          )}
          <Row className="mt1 mb1">
            <Col className="add-compare-col">
              <AddToCompare
                value="brakeDuration"
                compareValue={compareValue}
                updateCompareValue={updateCompareValue}
              />
            </Col>
          </Row>
          <Row>
            <Divider
              style={{
                background: "#eee",
                height: "4px",
                margin: "12px 0",
              }}
            />
          </Row>
          {vehicleDetails?.imeiNumber && (
            <PanelRow
              title={"Brake Distance"}
              timeSeriesURL={brakeDistanceTimeSeriesChart}
              histogramURL={brakeDistanceHistogramChart}
              boxPlotURL={brakeDistanceBoxPlotChart}
            />
          )}
          <Row className="mt1 mb1">
            <Col className="add-compare-col">
              <AddToCompare
                value="brakeDistance"
                compareValue={compareValue}
                updateCompareValue={updateCompareValue}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CardCollapse>
  );
};

export default BrakeAnalytics;
