import { useRef } from "react";
import "./style.css";
import "../index.css";
import Display from "./Display";
import { FloatButton } from "antd";
import { FullscreenOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Common/Constants";

const SlideShow = () => {
  const displayRef = useRef(null);
  const navigate = useNavigate();

  const openFullscreen = () => {
    const elem = displayRef.current;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  return (
    <>
      <FloatButton.Group shape="square" style={{ right: 15 }}>
        <FloatButton
          type="primary"
          onClick={openFullscreen}
          icon={<FullscreenOutlined style={{ fontSize: "24px" }} />}
          tooltip="Full Screen"
        />
        <FloatButton
          type="primary"
          onClick={() => navigate(ROUTES.LOGOUT.link)}
          icon={<LogoutOutlined style={{ fontSize: "24px" }} />}
          tooltip="Logout"
        />
      </FloatButton.Group>

      <div ref={displayRef}>
        <Display />
      </div>
    </>
  );
};

export default SlideShow;
