import { Col, Row, Card } from "antd";
import { UNITS } from "../../../Common/Constants";
import { getGrafanaInterval } from "../../../Common/Utils";
import { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import SPEED from "../../../../assets/images/icons/speed.svg";
import Iframe from "../../../Common/Iframe";
import { grafanaBaseUrl } from "../../../Common/endpoints";

const SpeedAnalytics = ({ tripSummary, summaryList }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const summaryCount = summaryList?.length;
  const [view, setView] = useState(false);

  const imeiURL =
    tripSummary?.imei?.length > 0
      ? "&var-imei=" + tripSummary.imei.join("&var-imei=")
      : "";
  const ridersURL =
    tripSummary?.riders?.length > 0
      ? "&var-riders=" + tripSummary.riders.join("&var-riders=")
      : "";
  const batteryURL =
    tripSummary?.battery?.length > 0
      ? "&var-battery=" + tripSummary.battery.join("&var-battery=")
      : "";

  const speedHistogramChart = `${
    grafanaBaseUrl
  }/d-solo/bf83e667-6e25-4c6a-950c-e099f47757bf/test-drive-cumulative-left?orgId=${
    userDetails?.currentOrganisation?.id
  }${imeiURL}${ridersURL}${batteryURL}&var-interval=${getGrafanaInterval(
    tripSummary?.startTime,
    tripSummary?.stopTime
  )}&from=${tripSummary?.startTime}&to=${tripSummary?.stopTime}&panelId=1`;

  const speedBoxChart = `${
    grafanaBaseUrl
  }/d-solo/bf83e667-6e25-4c6a-950c-e099f47757bf/test-drive-cumulative-left?orgId=${
    userDetails?.currentOrganisation?.id
  }${imeiURL}${ridersURL}${batteryURL}&var-interval=${getGrafanaInterval(
    tripSummary?.startTime,
    tripSummary?.stopTime
  )}&from=${tripSummary?.startTime}&to=${tripSummary?.stopTime}&panelId=4`;

  const onViewChange = () => {
    setView(!view);
  };

  return (
    <Card
      onClick={onViewChange}
      className="cursor-pointer"
      title={
        <Row justify={"space-between"}>
          <Col className="align-center">
            <img src={SPEED} alt="Speed" className="icon-size mr05" />
            Speed Analytics
          </Col>
          <Col className="font-weight-normal">
            View More
            {view ? (
              <UpOutlined className="ml05" />
            ) : (
              <DownOutlined className="ml05" />
            )}
          </Col>
        </Row>
      }
    >
      <Row gutter={[16, 16]} className="mt05" onClick={onViewChange}>
        <Col
          xs={24}
          sm={12}
          md={summaryCount >= 2 ? 24 : 12}
          lg={summaryCount >= 2 ? 12 : 8}
          xl={summaryCount >= 2 ? 12 : 8}
          xxl={8}
        >
          Average: {tripSummary?.averageSpeed?.toFixed(2)} {UNITS.KM_PER_HR}{" "}
        </Col>
        <Col
          xs={24}
          sm={12}
          md={summaryCount >= 2 ? 24 : 12}
          lg={summaryCount >= 2 ? 12 : 8}
          xl={summaryCount >= 2 ? 12 : 8}
          xxl={8}
        >
          Statistical Mode: {tripSummary?.statisticalSpeedMode?.toFixed(2)}{" "}
          {UNITS.KM_PER_HR}
        </Col>
        <Col
          xs={24}
          sm={12}
          md={summaryCount >= 2 ? 24 : 12}
          lg={summaryCount >= 2 ? 12 : 8}
          xl={summaryCount >= 2 ? 12 : 8}
          xxl={8}
        >
          Top Value: {tripSummary?.topSpeed?.toFixed(2)} {UNITS.KM_PER_HR}
        </Col>
      </Row>
      {view ? (
        <Row gutter={[16, 16]} className="mt2">
          <Col xs={24} lg={summaryCount >= 2 ? 24 : 12} xxl={12}>
            <Iframe
              title="Histogram - Cumulative Speed"
              src={speedHistogramChart}
              width="100%"
              height="360"
            />
          </Col>
          <Col xs={24} lg={summaryCount >= 2 ? 24 : 12} xxl={12}>
            <Iframe
              title="Box Plot - Cumulative Speed"
              src={speedBoxChart}
              width="100%"
              height="360"
            />
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};

export default SpeedAnalytics;
