import { ORGANISATIONS } from "./Constants";

export const getDomain = () => {
  const domainParts = window.location.hostname.split(".");
  const subdomain = domainParts.length >= 2 ? domainParts[0] : "";
  const isDemo = process.env.REACT_APP_ENV === "demo";

  switch (subdomain) {
    case ORGANISATIONS.NDS.KEY:
      return isDemo
        ? ORGANISATIONS.NDS.DOMAINS.DEMO
        : ORGANISATIONS.NDS.DOMAINS.DEV;
    case ORGANISATIONS.LML.KEY:
      return isDemo
        ? ORGANISATIONS.LML.DOMAINS.DEMO
        : ORGANISATIONS.LML.DOMAINS.DEV;
    case ORGANISATIONS.NICHESOLV.KEY:
      return isDemo
        ? ORGANISATIONS.NICHESOLV.DOMAINS.DEMO
        : ORGANISATIONS.NICHESOLV.DOMAINS.DEV;
    case ORGANISATIONS.EVDEALER.KEY:
      return isDemo
        ? ORGANISATIONS.EVDEALER.DOMAINS.DEMO
        : ORGANISATIONS.EVDEALER.DOMAINS.DEV;
    default:
      return isDemo
        ? ORGANISATIONS.NICHESOLV.DOMAINS.DEMO
        : ORGANISATIONS.NICHESOLV.DOMAINS.DEV;
  }
};
// export const userApiBaseUrl = `${getDomain()}/user-mgmt`;
// export const vehicleApiBaseUrl = `${getDomain()}/ev`;
export const userApiBaseUrl = process.env.REACT_APP_USER_API_BASE_URL;
export const vehicleApiBaseUrl = process.env.REACT_APP_VEHICLE_API_BASE_URL;
export const grafanaBaseUrl = process.env.REACT_APP_GRAFANA_PROXY_BASE_URL;

export const userJwtLoginUrl =
  userApiBaseUrl + process.env.REACT_APP_USER_JWT_LOGIN_URL;

export const userJwtRegisterUrl =
  userApiBaseUrl + process.env.REACT_APP_USER_JWT_REGISTER_URL;

export const userJwtLoginSchemaUrl =
  userApiBaseUrl + process.env.REACT_APP_USER_JWT_LOGIN_SCHEMA_URL;

export const userJwtRegisterSchemaUrl =
  userApiBaseUrl + process.env.REACT_APP_USER_JWT_REGISTER_SCHEMA_URL;

export const imeiListApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_IMEI_LIST_URL;

export const vehicleDetailsApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_DETAILS_URL;

export const vehicleTestStartApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_START_URL;

export const vehicleTestStopApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_STOP_URL;

export const vehicleTestResultApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_RESULT_URL;

export const vehicleAddCommentsApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_ADD_COMMENTS_URL;

export const roleListApiUrl =
  userApiBaseUrl + process.env.REACT_APP_ROLE_LIST_URL;

export const jwtConfirmUrl =
  userApiBaseUrl + process.env.REACT_APP_JWT_CONFIRM_EMAIL_URL;

export const vehicleTestListUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_LIST_URL;

export const jwtResetPasswordUrl =
  userApiBaseUrl + process.env.REACT_APP_JWT_RESET_PASSWORD_URL;

export const jwtChangePasswordUrl =
  userApiBaseUrl + process.env.REACT_APP_JWT_CHANGE_PASSWORD_URL;

export const changePasswordSchemaUrl =
  userApiBaseUrl + process.env.REACT_APP_JWT_CHANGE_PASSWORD_SCHEMA_URL;

export const vehicleTripSummaryUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TRIP_SUMMARY_URL;

export const testRideFormSubmitApiUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_RIDE_FORM_SUBMIT_URL;

export const testRideListUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_RIDE_LIST_URL;

export const testRideFilterUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_RIDE_FILTER_URL;

export const testRideFilterCountUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_RIDE_FILTER_COUNT_URL;

export const testRideCumulativeUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_TEST_RIDE_CUMULATIVE_URL;

export const organisationInfoUrl =
  userApiBaseUrl + process.env.REACT_APP_ORGANISATION_INFO_URL;

export const citiesUrl = vehicleApiBaseUrl + process.env.REACT_APP_CITIES_URL;

export const fleetsUrl = vehicleApiBaseUrl + process.env.REACT_APP_FLEETS_URL;
export const executiveMetaDataUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_EXECUTIVE_METADATA_URL;

export const executiveSparklingDataUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_EXECUTIVE_SPARKLING_URL;

export const executiveStackDataUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_EXECUTIVE_STACK_DATA_URL;

export const executiveImeiDataUrl =
  vehicleApiBaseUrl + process.env.REACT_APP_VEHICLE_EXECUTIVE_IMEI_DATA_URL;
