import { Col, Row, Modal, notification, Button } from "antd";
import BatteryPerformance from "./Analytics/Battery";
import BrakeAnalytics from "./Analytics/Brake";
import RideAnalytics from "./Analytics/Ride";
import SpeedAnalytics from "./Analytics/Speed";
import Overview from "./Overview";
import SpeedMap from "./SpeedMap";
import TripHighlight from "./TripHighlight";
import VehicleDetails from "../../VehicleDetails";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import GrafanaCompare from "./grafanaCompare";
import { grafanaBaseUrl } from "../../../Common/endpoints";
import TripStopAnalytics from "./Analytics/TripStops";
import AccelerationAnalytics from "./Analytics/Acceleration";
import EnergyAnalytics from "./Analytics/ Energy";
import DecelerationAnalytics from "./Analytics/Deceleration";

const RideStatistics = ({ onChangeImei, imei }) => {
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const tripSummary = useSelector((state) => state.testRide.tripSummary);
  const [api, contextHolder] = notification.useNotification({ maxCount: 1 });
  const [compareValue, setCompareValue] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const testDriveSummaryPanelBaseURL = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary`;

  const showModal = () => {
    setIsModalOpen(true);
    api.destroy();
  };

  useEffect(() => {
    if (compareValue?.length > 0) {
      api.open({
        message: "",
        closeIcon: false,
        key: "compare",
        description: [
          <Button type="submit" onClick={showModal}>
            Compare ({compareValue?.length})
          </Button>,
        ],
        duration: 0,
        maxCount: 1,
        className: "compare-notification",
        placement: "bottomRight",
      });
    } else {
      api.destroy();
    }

    // eslint-disable-next-line
  }, [api, compareValue]);

  const updateCompareValue = (name, value) => {
    if (compareValue?.length > 2 && value) {
      toast.error(`Maximum 3 graphs can be compare`);
    } else {
      setCompareValue((prev) =>
        value ? [...prev, name] : prev.filter((item) => item !== name)
      );
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCompareValue([]);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <VehicleDetails onChangeImei={onChangeImei} imei={imei} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={19} xl={19}>
          <Overview />
        </Col>
      </Row>
      <Row className="mt1">
        <Col span={24}>
          <TripHighlight />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <SpeedMap />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt075">
        <Col span={24}>
          <SpeedAnalytics
            compareValue={compareValue}
            updateCompareValue={updateCompareValue}
          />
        </Col>
        <Col span={24}>
          <BatteryPerformance
            compareValue={compareValue}
            updateCompareValue={updateCompareValue}
          />
        </Col>
        <Col span={24}>
          <BrakeAnalytics
            compareValue={compareValue}
            updateCompareValue={updateCompareValue}
          />
        </Col>
        <Col span={12}>
          <TripStopAnalytics
            compareValue={compareValue}
            baseURL={testDriveSummaryPanelBaseURL}
            updateCompareValue={updateCompareValue}
          />
        </Col>
        <Col span={12}>
          <EnergyAnalytics
            compareValue={compareValue}
            baseURL={testDriveSummaryPanelBaseURL}
            updateCompareValue={updateCompareValue}
          />
        </Col>
        <Col span={12}>
          <AccelerationAnalytics
            compareValue={compareValue}
            baseURL={testDriveSummaryPanelBaseURL}
            updateCompareValue={updateCompareValue}
          />
        </Col>
        <Col span={12}>
          <DecelerationAnalytics
            compareValue={compareValue}
            baseURL={testDriveSummaryPanelBaseURL}
            updateCompareValue={updateCompareValue}
          />
        </Col>
        <Col span={24}>
          <RideAnalytics />
        </Col>
      </Row>
      {contextHolder}
      <Modal
        title="Compare"
        width={"90%"}
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        className="compare-modal"
        footer={null}
      >
        <GrafanaCompare
          vehicleDetails={vehicleDetails}
          baseURL={testDriveSummaryPanelBaseURL}
          compareCharts={compareValue}
          startTime={tripSummary?.rideForm?.startTime}
          stopTime={tripSummary?.rideForm?.stopTime}
        />
      </Modal>
    </>
  );
};

export default RideStatistics;
