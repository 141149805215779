import { Col, Divider, Drawer, Dropdown, Layout, Menu, Row, Space } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { useOrganization } from "../../../OrganizationProvider";
import {
  dashboardsList,
  dealerTheme,
  footerText,
  userPages,
} from "../../Common/Utils";
import { CaretDownFilled, MenuOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import DASHBOARD from "../../../assets/images/icons/dashboard.svg";
import AVATAR from "../../../assets/images/icons/avatar.svg";
import MOBILE_AVATAR from "../../../assets/images/icons/mob-avatar.svg";
import { useNavigate } from "react-router-dom";

const DealerLayout = (props) => {
  const navigate = useNavigate();
  const organization = useOrganization();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const currentRole = userDetails?.currentRole;
  const currentOrganisation = userDetails?.currentOrganisation;
  const userLinks = userPages(JSON.parse(localStorage.getItem("userDetails")));
  const decodedJwt = localStorage.getItem("authToken")
    ? jwtDecode(localStorage.getItem("authToken"))
    : "";

  const sessionTimeout = useCallback(() => {
    const isTokenExpired = Math.floor(Date.now() / 1000) > decodedJwt?.exp;
    if (isTokenExpired) {
      localStorage.clear();
      window.location.reload();
    }
  }, [decodedJwt?.exp]);

  useEffect(() => {
    sessionTimeout();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sessionTimeout]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onClickItem = (e) => {
    navigate(`/${e.key}`);
  };

  const onDropdownClick = (e) => {
    navigate(`/${e.key}`);
  };

  return (
    <Layout
      style={{
        backgroundColor: dealerTheme[organization?.slug],
        minHeight: "100vh",
      }}
    >
      <Header className="dealer-bg">
        <Row justify="space-between" className="h100">
          <Col xs={8} sm={6} md={4} lg={3} xl={2} className="flex-center">
            <a href="/">
              <img
                src={`${organization.s3BucketBaseUrl}/InnerLogo.svg`}
                className="flex"
                alt="Logo"
              />
            </a>
          </Col>

          <Col xs={4} sm={12} md={12} lg={10} xl={8} className="flex-center">
            {isMobile ? (
              <>
                <MenuOutlined onClick={toggleDrawer} className="mob-menu" />

                <Drawer
                  title={
                    <div className="flex">
                      <img src={MOBILE_AVATAR} alt="avatar" className="mr1" />
                      {`${currentOrganisation?.name} - ${currentRole}`}
                    </div>
                  }
                  placement="right"
                  closable={false}
                  onClose={toggleDrawer}
                  open={isDrawerOpen}
                >
                  {dashboardsList().map((item) => (
                    <div key={item.key} className="drawer-pd">
                      <a href={`/${item.key}`}>
                        <span>{item.label}</span>
                      </a>
                    </div>
                  ))}

                  <Divider />
                  {userLinks.map(
                    (item) =>
                      item.key && (
                        <div key={item.key} className="drawer-pd">
                          <a href={`/${item.key}`}>
                            <span>{item.label}</span>
                          </a>
                        </div>
                      )
                  )}
                </Drawer>
              </>
            ) : (
              <>
                <Menu
                  onClick={onClickItem}
                  mode="horizontal"
                  className="ml-auto"
                  items={[]}
                  theme="dark"
                />

                <Dropdown
                  menu={{
                    onClick: onDropdownClick,
                    items: dashboardsList(),
                  }}
                >
                  <Space className="mr1075">
                    <img
                      src={DASHBOARD}
                      alt="Dashboards"
                      className="icon-margin"
                    />
                    Dashboards
                    <CaretDownFilled />
                  </Space>
                </Dropdown>

                <Dropdown
                  menu={{
                    onClick: onDropdownClick,
                    items: userLinks,
                  }}
                >
                  <Space>
                    <img src={AVATAR} alt="avatar" className="icon-margin" />
                    {`${currentOrganisation?.name} - ${currentRole}`}
                    <CaretDownFilled />
                  </Space>
                </Dropdown>
              </>
            )}
          </Col>
        </Row>
      </Header>
      <Layout
        className="layout"
        style={{
          backgroundColor: dealerTheme[organization?.slug],
        }}
      >
        <Content>{props.children}</Content>
      </Layout>
      <Footer className="dealer-bg flex-center">{footerText}</Footer>
    </Layout>
  );
};

export default DealerLayout;
