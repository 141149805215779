export const motorFields = [
  {
    label: "DC Current",
    value: "motor_dc_current",
  },
  {
    label: "DC Voltage",
    value: "motor_dc_voltage",
  },
  {
    label: "MCS Temperature",
    value: "motor_mcs_temperature",
  },
  {
    label: "Motor Speed",
    value: "motor_speed",
  },
  {
    label: "Motor Temperature",
    value: "motor_temperature",
  },
  {
    label: "Motor Throttle",
    value: "motor_throttle",
  },
];

export const batteryFields = [
  {
    label: "Voltage",
    value: "voltage",
  },
  {
    label: "Temperature",
    value: "temperature",
  },
];
